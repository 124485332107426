@charset "UTF-8";
/*
Description: Rememberland 2022
Version: 1.0.0
Author: Hakan Kamci
Author URI: hkamci@gmail.com
*/
img {
  display: block;
  max-width: 100%;
  height: auto;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
  position: relative;
}

/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
  content: attr(data-char);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  user-select: none;
}

/* Expanded CSS Variables */
.splitting {
  /* The center word index */
  --word-center: calc((var(--word-total) - 1) / 2);
  /* The center character index */
  --char-center: calc((var(--char-total) - 1) / 2);
  /* The center character index */
  --line-center: calc((var(--line-total) - 1) / 2);
}

.splitting .word {
  /* Pecent (0-1) of the word's position */
  --word-percent: calc(var(--word-index) / var(--word-total));
  /* Pecent (0-1) of the line's position */
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  /* Percent (0-1) of the char's position */
  --char-percent: calc(var(--char-index) / var(--char-total));
  /* Offset from center, positive & negative */
  --char-offset: calc(var(--char-index) - var(--char-center));
  /* Absolute distance from center, only positive */
  --distance: calc(
     (var(--char-offset) * var(--char-offset)) / var(--char-center)
  );
  /* Distance from center where -1 is the far left, 0 is center, 1 is far right */
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  /* Distance from center where 1 is far left/far right, 0 is center */
  --distance-percent: calc((var(--distance) / var(--char-center)));
}

.splitting {
  cursor: pointer;
}

/* ---------------------------------- */
.slide-vertical .char {
  overflow: hidden;
  color: transparent;
}
.slide-vertical .char:before, .slide-vertical .char:after {
  visibility: visible;
  color: #FFF3B9;
  transition: transform 0.5s cubic-bezier(0.9, 0, 0.2, 1);
  transition-delay: calc(0.2s + 0.02s * (var(--char-index)));
}
.slide-vertical .char:before {
  color: #FFF3B9;
  transition-delay: calc(0.02s * (var(--char-index)));
}
.slide-vertical:hover .char:before {
  transition-delay: calc(0.2s + 0.02s * (var(--char-index)));
}
.slide-vertical:hover .char:after {
  transition-delay: calc(0.02s * (var(--char-index)));
}

.slide-vertical .char:before {
  transform: translateY(100%);
}
.slide-vertical:hover .char:before {
  transform: translateY(0%);
}
.slide-vertical:hover .char:after {
  transform: translateY(-100%);
}

.rememberland-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.65);
  /* backdrop-filter: blur(20px); */
  padding: 24px 42px;
  display: flex;
  align-items: center;
}
.rememberland-header .rememberland-logo-white {
  width: 156px;
}
.rememberland-header .mobile-hamburger {
  display: none;
  width: 24px;
}
.rememberland-header .mobile-user-avatar {
  display: none;
}
@media (max-width: 64em) {
  .rememberland-header {
    background-color: rgba(255, 255, 255, 0.06);
    padding: 20px 16px 16px;
    z-index: 102;
    justify-content: space-between;
  }
  .rememberland-header .rememberland-logo-white {
    filter: invert(100%) sepia(8%) saturate(33%) hue-rotate(274deg) brightness(108%) contrast(107%);
  }
  .rememberland-header .mobile-hamburger {
    display: block;
  }
  .rememberland-header .mobile-user-avatar {
    display: block;
  }
}

.rememberland-nav-top {
  position: fixed;
  top: 16px;
  right: 24px;
  z-index: 101;
  /* transition: all 0.3s ease-out; */
}
.rememberland-nav-top.wo-anim {
  transition: none;
}
.rememberland-nav-top .rememberland-header-right {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
}
.rememberland-nav-top .rememberland-logo-white {
  width: 156px;
  filter: invert(100%) sepia(8%) saturate(33%) hue-rotate(274deg) brightness(108%) contrast(107%);
}
.rememberland-nav-top .rememberland-header-right a {
  font-size: 12px;
  color: #111;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.rememberland-nav-top .rememberland-header-right a.close-mobile-hamburger {
  display: none;
}
.rememberland-nav-top .rememberland-header-right a.read-more {
  border: 1px solid #111;
  color: #111 !important;
  padding: 14px 24px 0px;
  margin: 0 24px 0 16px;
  line-height: 2;
  /* transition: all 0.3s ease-out; */
}
.rememberland-nav-top .rememberland-header-right a.read-more .char:after {
  color: #111 !important;
}
.rememberland-nav-top .rememberland-header-right a.login {
  border: 1px solid #111;
  color: #111;
  padding: 14px 24px 0px;
  margin-left: 24px;
  line-height: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: all 0.3s ease-out; */
}
.rememberland-nav-top .rememberland-header-right a.login img {
  margin-right: 8px;
}
.rememberland-nav-top .rememberland-header-right a.login .char:before {
  color: #111 !important;
}
.rememberland-nav-top .rl-lang-part {
  position: relative;
}
.rememberland-nav-top .rl-lang {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.rememberland-nav-top .rl-lang img {
  /* transition: all 0.3s ease-out; */
}
.rememberland-nav-top .rl-lang.open img {
  transform: rotate(180deg);
}
.rememberland-nav-top .rl-lang + .rl-lang-dropdown {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 10;
  background-color: #fff;
  padding: 12px 16px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  /* transition: all 0.3s ease-out; */
}
.rememberland-nav-top .rl-lang + .rl-lang-dropdown.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.rememberland-nav-top .rl-lang + .rl-lang-dropdown a {
  color: #111;
}
.rememberland-nav-top .rememberland-header-right-mobile-header {
  display: none;
}
.rememberland-nav-top .slide-vertical .char:before, .rememberland-nav-top .slide-vertical .char:after {
  color: #111;
}
.rememberland-nav-top .slide-vertical .char:before {
  color: #111;
}
@media (max-width: 64em) {
  .rememberland-nav-top {
    padding: 20px 16px;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    /* transition: all 0.15s ease-out; */
  }
  .rememberland-nav-top .rememberland-header-right {
    background: #1C1F37;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: calc(100vh - (100vh - 100%));
    height: 100svh;
    /* height: -webkit-fill-available; */
    width: 100%;
    padding: 110px 24px 24px;
    /* transition: all 0.3s ease-out; */
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .rememberland-nav-top .rememberland-header-right a {
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
    margin-bottom: 16px;
    padding: 24px;
    font-size: 14px;
  }
  .rememberland-nav-top .rememberland-header-right a.close-mobile-hamburger {
    display: block;
  }
  .rememberland-nav-top .rememberland-header-right a.read-more, .rememberland-nav-top .rememberland-header-right a.login {
    color: #fff !important;
    border-color: #fff !important;
    margin: 0 0 16px;
    padding: 24px;
  }
  .rememberland-nav-top .rememberland-header-right a.read-more:hover, .rememberland-nav-top .rememberland-header-right a.login:hover {
    background-color: transparent;
  }
  .rememberland-nav-top .rememberland-header-right a.read-more .char:before, .rememberland-nav-top .rememberland-header-right a.read-more .char:after, .rememberland-nav-top .rememberland-header-right a.login .char:before, .rememberland-nav-top .rememberland-header-right a.login .char:after {
    color: #fff !important;
  }
  .rememberland-nav-top .rememberland-header-right a.read-more .char:before, .rememberland-nav-top .rememberland-header-right a.login .char:before {
    color: #fff !important;
  }
  .rememberland-nav-top .rememberland-header-right .slide-vertical .char:before, .rememberland-nav-top .rememberland-header-right .slide-vertical .char:after {
    color: #fff !important;
  }
  .rememberland-nav-top .rememberland-header-right .slide-vertical .char:before {
    color: #fff !important;
  }
  .rememberland-nav-top .rememberland-header-right .rl-lang-part {
    display: none;
  }
  .rememberland-nav-top .rememberland-header-right .rl-lang-part + .rl-lang-dropdown {
    position: absolute;
    bottom: 24px;
    left: 24px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    padding: 0;
    top: auto;
    right: auto;
  }
  .rememberland-nav-top .rememberland-header-right .rl-lang-part + .rl-lang-dropdown a {
    display: block;
    text-align: center;
  }
  .rememberland-nav-top .rememberland-header-right.show {
    transform: translateX(0);
  }
  .rememberland-nav-top .rememberland-header-right.show:before {
    content: "";
    position: absolute;
    left: 69.74%;
    right: -113.59%;
    top: -17.75%;
    bottom: 54.72%;
    background: linear-gradient(180deg, #755CDD 0%, #E4559A 100%);
    opacity: 0.45;
    filter: blur(100px);
  }
  .rememberland-nav-top .rememberland-header-right.show:after {
    content: "";
    position: absolute;
    left: -71.54%;
    right: 27.69%;
    top: 99.21%;
    bottom: -62.25%;
    background: linear-gradient(180deg, rgba(255, 228, 157, 0.6) 0%, #E4559A 100%);
    opacity: 0.3;
    filter: blur(100px);
  }
  .rememberland-nav-top .rememberland-header-right.show .rememberland-header-right-mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(255, 253, 239, 0.1);
  }
  .rememberland-nav-top .rememberland-header-right.show .rememberland-header-right-mobile-header a {
    margin: 0;
    padding: 0;
    width: auto;
  }
  .rememberland-nav-top .rememberland-header-right.show .rememberland-header-right-mobile-header .close-mobile-hamburger {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(102%) contrast(105%);
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part {
    display: block;
    position: absolute;
    left: 24px;
    bottom: 24px;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang {
    display: none;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang-dropdown {
    position: relative;
    bottom: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    padding: 0;
    top: auto;
    right: auto;
    background-color: transparent;
    display: flex;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang-dropdown a {
    color: rgba(255, 255, 255, 0.4);
    display: block;
    text-align: center;
    padding: 16px;
    margin: 0;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang-dropdown a.active {
    color: #fff;
  }
  .rememberland-nav-top.show {
    z-index: 103;
    right: 0;
    transform: translateX(0);
  }

  .rememberland-nav-top .rememberland-header-right .features-mobile {
    padding: 4px;
    margin: 2px;
  }
}

.rememberland-footer {
  /* background: #151515 url("../img/landing-footer-bg-min.jpg") no-repeat top center; */
  background-color: #000;
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
  font-family: "Jost", sans-serif;
  font-weight: 500;
}
.rememberland-footer-left {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}
.rememberland-footer-left .logo {
  filter: invert(99%) sepia(12%) saturate(1318%) hue-rotate(306deg) brightness(98%) contrast(108%);
  width: 240px;
}
.rememberland-footer-left a {
  display: block;
  margin: 40px 0 0px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  text-decoration: underline;
}
.rememberland-footer-left div {
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
.rememberland-footer-right {
  text-align: right;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}
.rememberland-footer-right .button {
  border: 1px solid rgba(255, 243, 185, 0.17);
  color: #FFEBC4;
  display: inline-block;
  padding: 16px 32px 6px;
  margin-left: 10px;
  font-size: 18px;
  letter-spacing: 6px;
}
.rememberland-footer-right .bottom {
  margin-top: auto;
}
.rememberland-footer-right .bottom ul {
  display: flex;
  flex-flow: row wrap;
}
.rememberland-footer-right .bottom li:not(:last-child) {
  margin-right: 20px;
}
.rememberland-footer-right .bottom li {
  display: inline-block;
}
.rememberland-footer-right .bottom a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.rememberland-footer-right .bottom a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
}
.rememberland-footer-right .bottom img {
  filter: invert(92%) sepia(81%) saturate(388%) hue-rotate(317deg) brightness(98%) contrast(106%);
}
@media (max-width: 64em) {
  .rememberland-footer {
    display: none;
  }
}

.rememberland-footer-mobile {
  display: none;
  background-color: #13072A;
  padding: 32px;
  text-align: center;
}
.rememberland-footer-mobile .logo {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(343deg) brightness(104%) contrast(101%);
  width: 150px;
  margin: 0 auto 16px;
  opacity: 0.7;
}
.rememberland-footer-mobile ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 32px auto;
}
.rememberland-footer-mobile li:not(:last-child) {
  margin-right: 20px;
}
.rememberland-footer-mobile li {
  display: inline-block;
}
.rememberland-footer-mobile li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.rememberland-footer-mobile li a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
}
.rememberland-footer-mobile img {
  filter: invert(92%) sepia(81%) saturate(388%) hue-rotate(317deg) brightness(98%) contrast(106%);
}
.rememberland-footer-mobile .login {
  font-family: "Jost", sans-serif;
  font-size: 18px;
  padding: 12px 72px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  border: 1px solid rgba(255, 243, 185, 0.4);
  color: #FFEBC4;
  display: inline-block;
}
.rememberland-footer-mobile .contact-us {
  font-size: 13px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  letter-spacing: 0.24em;
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 64em) {
  .rememberland-footer-mobile {
    display: block;
  }
}

a {
  text-decoration: none;
}

img, video, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

iframe, object, embed {
  max-width: 100%;
  display: block;
}

.video-wrapper {
  position: relative;
  /* aspect-ratio: 16/9; */
}
.video-wrapper iframe, .video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

video {
  display: block;
  height: auto;
  margin: auto;
  position: relative;
  width: 100%;
}

/**/
.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-flex.row {
  flex-flow: row wrap;
}

.d-flex.col {
  flex-flow: column wrap;
}

.d-flex.column {
  flex-flow: column wrap;
}

.d-flex.align-center {
  align-items: center;
}

.d-flex.align-start {
  align-items: flex-start;
}

.d-flex.align-end {
  align-items: flex-end;
}

.d-flex.justify-center {
  justify-content: center;
}

.d-flex.justify-space {
  justify-content: space-between;
}

.d-flex.justify-around {
  justify-content: space-around;
}

.d-flex.justify-end {
  justify-content: flex-end;
}

.d-flex.justify-start {
  justify-content: flex-start;
}

.flex-grow-vert {
  flex: 1 0 auto;
}

.align-self-start {
  align-self: flex-start;
}

.global-col-1 {
  width: 100%;
}

.global-col-2 {
  width: 50%;
}
@media (max-width: 48em) {
  .global-col-2 {
    width: 100%;
    margin: 0;
  }
}

.global-col-3 {
  width: 33.3333333333%;
}
@media (max-width: 48em) {
  .global-col-3 {
    width: 100%;
  }
}

.global-col-1-3 {
  width: 33.3333333333%;
}
@media (max-width: 37.5em) {
  .global-col-1-3 {
    width: 100%;
  }
}

.global-col-2-3 {
  width: 66.6666666667%;
}
@media (max-width: 37.5em) {
  .global-col-2-3 {
    width: 100%;
  }
}

.global-col-1-4 {
  width: 25%;
}
@media (max-width: 37.5em) {
  .global-col-1-4 {
    width: 100%;
  }
}

.global-col-3-4 {
  width: 75%;
}
@media (max-width: 37.5em) {
  .global-col-3-4 {
    width: 100%;
  }
}

.global-col-7 {
  width: 58.3333333333%;
}
@media (max-width: 37.5em) {
  .global-col-7 {
    width: 100%;
  }
}

.global-col-5 {
  width: 41.6666666667%;
}
@media (max-width: 37.5em) {
  .global-col-5 {
    width: 100%;
  }
}

.flex-1 {
  flex: 1;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}
@media (max-width: 48em) {
  .w-50 {
    width: 100% !important;
  }
}

.w-30 {
  width: 30% !important;
}

.min-height-100 {
  min-height: 100vh;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.form-label {
  display: block;
  line-height: 1;
  user-select: none;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

fieldset {
  position: relative;
}
fieldset .icon-link {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #1A1A1A;
  font-size: 18px;
}
fieldset .form-input.with-icon-link {
  width: calc(100% - 24px);
}

/**
  * Component Element
  */
.form-input,
.form-textarea,
.form-select {
  background-color: #fff;
  border: thin solid #CECED6;
  border-radius: 6px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  outline: none;
  outline-style: none;
  padding: 14px;
  position: relative;
  appearance: none;
}
.form-input.w-border,
.form-textarea.w-border,
.form-select.w-border {
  border: 1px solid #CECED6;
}
.form-input.error,
.form-textarea.error,
.form-select.error {
  border: 2px solid #B65E49;
}
.form-input.small,
.form-textarea.small,
.form-select.small {
  padding: 8px 10px;
}

.form-select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  padding-right: 30px;
  padding: 14px 30px 14px 12px;
  font-weight: 400;
}
.form-select:after {
  color: #000;
  content: "▾";
  display: block;
  height: 14px;
  position: absolute;
  right: 24px;
  top: 26px;
  width: 14px;
}

.form-textarea {
  font-size: 16px;
  min-height: 140px;
  line-height: 1.6;
}

.file-upload {
  position: relative;
  display: inline-block;
}
.file-upload .file-upload__label {
  display: block;
  padding: 12px 30px;
  color: #1A1A1A;
  background: #fff;
  border-radius: 4px;
  transition: background 0.3s;
}
.file-upload .file-upload__label:hover {
  cursor: pointer;
  background: #fff;
  color: #1A1A1A;
}
.file-upload .file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}
.file-upload.disabled {
  opacity: 0.5;
  cursor: default;
}
.file-upload.disabled .file-upload__label:hover {
  cursor: default;
  color: #1A1A1A;
  background: #fff;
}

.file-upload-mobile {
  position: relative;
  display: inline-block;
}
.file-upload-mobile .file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}

.form-checkbox-outer label {
  display: flex;
  align-items: center;
  font-size: 10px;
}

.form-radio,
.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #fff;
  border: thin solid #1A1A1A;
  color: #1A1A1A;
  height: 16px;
  width: 16px;
  border-radius: 0;
  cursor: pointer;
  margin: 0 7px 0 0;
  outline: none;
  transition: all 0.3s ease-out;
}
.form-radio:checked::before,
.form-checkbox:checked::before {
  position: absolute;
  left: 4px;
  top: 1px;
  content: "⅃";
  transform: rotate(40deg);
  font-size: 9px;
}
.form-radio:checked,
.form-checkbox:checked {
  background-color: #1A1A1A;
  border-color: #1A1A1A;
  color: #fff;
  z-index: 2;
}

.form-radio {
  border: 1px solid #1A1A1A;
  border-radius: 50%;
  top: 6px;
  transition: all 0.3s ease-out;
}
.form-radio:before {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-out;
}
.form-radio:checked {
  background-color: transparent;
  border-color: #1A1A1A;
  color: #fff;
  z-index: 2;
  transition: all 0.3s ease-out;
}
.form-radio:checked:before {
  content: "";
  background: #1A1A1A;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form-checkbox {
  border-radius: 3px;
}

.error-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #B65E49;
  margin-top: 0.25rem;
}

button {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
button:focus {
  outline: 0;
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1000;
  padding: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 1280px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding: 72px 32px 40px;
  text-align: left;
}
.modal.column {
  flex-flow: column wrap;
}
.modal-400 {
  max-width: 400px;
}

.modal a.close-modal {
  position: absolute;
  top: 32px;
  right: 32px;
  display: block;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
}
.modal a.close-modal:after {
  content: "×";
  position: absolute;
  top: 0;
  right: 0;
  text-indent: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #111;
}

.modal-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 16px;
}
.modal-header-desc {
  font-size: 14px;
  margin-bottom: 32px;
}

@media (max-width: 48em) {
  .modal {
    padding: 56px 16px 16px;
  }
  .modal a.close-modal {
    top: 16px;
    right: 16px;
  }
  .modal-header {
    font-size: 20px;
  }
  .modal-header-desc {
    font-size: 12px;
    margin-bottom: 24px;
  }
}
.verse-img {
  margin: 0 auto 30px;
}

.rl-verse-modal {
  max-width: 65vw;
}
.rl-verse-modal h1 {
  font-size: 40px;
  margin-bottom: 20px;
}
.rl-verse-modal .modal-header-desc {
  max-width: 540px;
  margin: 0 auto 32px;
}
.rl-verse-modal .numbers span {
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 12px;
  margin-bottom: 20px;
  display: inline-block;
}
.rl-verse-modal .numbers span span {
  color: #757683;
  margin-bottom: 0;
}
.rl-verse-modal .numbers .rl-progress-bar {
  max-width: 300px;
  margin: 0 auto 24px;
}
@media (max-width: 48em) {
  .rl-verse-modal h1 {
    font-size: 24px;
  }
}
@media (max-width: 37.5em) {
  .rl-verse-modal {
    max-width: 90vw;
    padding: 56px 48px 40px;
  }
}

.rl-progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background-color: #EAEBF2;
  border: 1px solid #E9EBF3;
  position: relative;
}
.rl-progress-bar-fill {
  height: 8px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.rl-progress-bar-fill.pink {
  background-color: #DD89DF;
}
.rl-progress-bar-fill.turquaz {
  background-color: #4EC6CE;
}
.rl-progress-bar-fill.orange {
  background-color: #F88B84;
}

.rl-btn-filled {
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.rl-btn-filled:hover {
  opacity: 0.6;
}
.rl-btn-filled.black {
  background-color: #1A1A1A;
  color: #fff;
}
.rl-btn-filled.w-220 {
  width: 220px;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}
html.html-no-scroll {
  overflow-y: hidden;
  overscroll-behavior: none;
}

body {
  background-color: #231B39;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
body.body-no-scroll {
  overflow: hidden;
  overscroll-behavior: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Inter", serif;
  line-height: 1.3;
}

h1,
.h1 {
  font-size: 24px;
  line-height: 29.05px;
}

h2,
.h2 {
  font-size: 20px;
  line-height: 24.2px;
}

p:not(:last-child) {
  margin-bottom: 1.5rem;
}

i {
  font-style: italic;
}

b {
  font-weight: bold;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.bold,
strong {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-not-uppercase {
  text-transform: initial !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.cursor-default {
  cursor: default !important;
}

ul.unordered {
  list-style: disc;
  list-style-position: inside;
}
ul.unordered li {
  margin-bottom: 10px;
}

ol.ordered {
  list-style: decimal;
  list-style-position: inside;
}
ol.ordered li {
  margin-bottom: 20px;
}

.text-404 {
  font-size: 18rem;
  font-weight: 400;
  color: #fff;
}
@media (max-width: 48em) {
  .text-404 {
    font-size: 10rem;
  }
}

.lh-1 {
  line-height: 1;
}

.lh-1-3 {
  line-height: 1.3;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.str-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.op-50 {
  opacity: 0.5;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.font-body {
  font-family: "Inter", sans-serif;
}

.font-header {
  font-family: "Inter", serif;
}

.rememberland-landing-hero {
  position: relative;
  height: 100vh;
}
.rememberland-landing-hero-video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.rememberland-landing-hero-read-more {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 200px;
  z-index: 1;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 6px;
  padding: 24px 48px 16px;
  color: #fff;
  border: 1px solid #FFF3B9;
  background-color: rgba(17, 17, 17, 0.12);
}
.rememberland-landing-hero-sound {
  position: absolute;
  bottom: 42px;
  left: 36px;
  z-index: 2;
  display: flex;
  align-items: center;
}
.rememberland-landing-hero-sound img {
  filter: invert(75%) sepia(69%) saturate(289%) hue-rotate(2deg) brightness(106%) contrast(102%);
}
.rememberland-landing-hero-sound span {
  font-family: "Jost", sans-serif;
  font-size: 12px;
  margin-left: 6px;
  color: #FFF3B9;
}
.rememberland-landing-hero-bottom-center {
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}
.rememberland-landing-hero-social {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0.5;
}
.rememberland-landing-hero-social li:not(:last-child) {
  margin-right: 14px;
}
.rememberland-landing-hero-social img {
  filter: invert(91%) sepia(76%) saturate(589%) hue-rotate(320deg) brightness(107%) contrast(101%);
}
@media (max-width: 48em) {
  .rememberland-landing-hero-read-more {
    bottom: 160px;
    font-size: 20px;
    word-break: keep-all;
    width: 74%;
    text-align: center;
  }
  .rememberland-landing-hero-sound {
    left: 16px;
    bottom: 16px;
  }
  .rememberland-landing-hero-sound span {
    display: none;
  }
  .rememberland-landing-hero-bottom-center {
    display: none;
  }
  .rememberland-landing-hero-social {
    display: flex;
  }
}

.rememberland-landing-body {
  background: #231B39 url("../img/landing-bg-desktop-min.jpg") no-repeat top center;
  background-size: cover;
}

.rememberland-landing-for-whom {
  padding: 164px 40px 200px;
  text-align: center;
}
.rememberland-landing-for-whom-wrapper {
  max-width: 62vw;
  width: 100%;
  margin: 0 auto;
}
.rememberland-landing-for-whom-title {
  font-size: 65px;
  font-weight: 700;
  font-family: "Jost", sans-serif;
  margin-bottom: 1.5rem;
  letter-spacing: 0.2vw;
  color: #fff;
}
.rememberland-landing-for-whom-desc {
  font-size: 24px;
  line-height: 35px;
  font-family: "Jost", sans-serif;
  margin: 0 auto 3rem;
  text-align: center;
  color: #fff;
  width: 64vw;
}
.rememberland-landing-for-whom-desc br {
  display: none;
}
.rememberland-landing-for-whom-top {
  color: #fff;
  text-transform: uppercase;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 4px;
  margin-bottom: 12vh;
}
.rememberland-landing-for-whom-bottom {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.rememberland-landing-for-whom-bottom img {
  margin: 0 auto;
}
.rememberland-landing-for-whom-bottom-w-link {
  text-align: center;
  font-family: "Jost", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 57.6px;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 48%;
}
.rememberland-landing-for-whom-bottom .title {
  margin: 30px auto 60px;
}
.rememberland-landing-for-whom-bottom a {
  display: inline-block;
  font-size: 20px;
  line-height: 2;
  padding: 22px 48px 0px;
  color: #FFF3B9;
  border: 1px solid #FFF3B9;
  background-color: rgba(17, 17, 17, 0.12);
}
.rememberland-landing-for-whom-bottom a.payment {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  padding: 28px 48px;
  color: #FFF3B9;
  border: 1px solid #FFF3B9;
  background-color: rgba(17, 17, 17, 0.12);
}
@media (max-width: 48em) {
  .rememberland-landing-for-whom {
    padding: 140px 30px 20px;
  }
  .rememberland-landing-for-whom-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .rememberland-landing-for-whom-title {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1.75vw;
    margin-bottom: 1.5rem;
  }
  .rememberland-landing-for-whom-desc {
    font-size: 15px;
    line-height: 21px;
    padding: 0 2rem;
    text-align: justify;
    width: 100%;
  }
  .rememberland-landing-for-whom-top {
    font-size: 14px;
  }
  .rememberland-landing-for-whom-bottom .middle {
    display: none;
  }
  .rememberland-landing-for-whom-bottom img {
    width: 160px;
    height: 160px;
  }
  .rememberland-landing-for-whom-bottom div {
    width: 100%;
    margin-bottom: 100px;
  }
  .rememberland-landing-for-whom-bottom .title {
    margin: 30px auto 24px;
  }
  .rememberland-landing-for-whom-bottom-w-link {
    font-size: 20px;
    line-height: 32px;
  }
  .rememberland-landing-for-whom-bottom a {
    font-size: 15px;
    padding: 24px 48px 18px;
  }
}

.rl-animated-btn {
  display: inline-block;
  font-size: 20px;
  line-height: 2;
  padding: 22px 48px 0px;
  color: #fff;
  border: 1px solid #FFF3B9;
  background-color: rgba(17, 17, 17, 0.12);
  font-family: "Jost", sans-serif;
  font-weight: 500;
  color: #FFF3B9;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
}
@media (max-width: 48em) {
  .rl-animated-btn {
    font-size: 15px;
    padding: 24px 48px 18px;
  }
}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  color: #FFF3B9;
}
.swiper .swiper-button-next,
.swiper .swiper-rtl .swiper-button-prev {
  right: 25%;
}
.swiper .swiper-button-prev,
.swiper .swiper-rtl .swiper-button-next {
  left: 25%;
}
.swiper .swiper-pagination-bullet {
  transform: rotate(45deg);
  border-radius: 0;
  background: transparent;
  border: 1px solid #FFF3B9;
  opacity: 1;
  width: 8px;
  height: 8px;
  position: relative;
}
.swiper .swiper-pagination-bullet:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 1px solid #FFF3B9;
  opacity: 0;
}
.swiper .swiper-pagination-bullet-active {
  background: #FFF3B9;
}
.swiper .swiper-pagination-bullet-active:after {
  opacity: 1;
}
.swiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px;
}
.swiper .swiper-pagination {
  position: relative;
  margin-top: 16px;
  display: none;
}
@media (max-width: 64em) {
  .swiper .swiper-button-next,
  .swiper .swiper-rtl .swiper-button-prev {
    right: 5%;
  }
  .swiper .swiper-button-prev,
  .swiper .swiper-rtl .swiper-button-next {
    left: 5%;
  }
}
@media (max-width: 48em) {
  .swiper .swiper-button-next,
  .swiper .swiper-button-prev {
    display: none;
  }
  .swiper .swiper-pagination {
    display: block;
  }
}

.rememberland-landing-gravyeards {
  padding: 80px 40px 200px;
  text-align: center;
}
.rememberland-landing-gravyeards .title {
  font-size: 65px;
  font-family: "Jost", sans-serif;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2vw;
  margin-bottom: 2rem;
}
.rememberland-landing-gravyeards .desc {
  color: #fff;
  font-family: "Jost", sans-serif;
  font-size: 24px;
  line-height: 35px;
}
.rememberland-landing-gravyeards .rl-grave-fill {
  margin: 24px auto 16px;
  width: 300px;
  color: #fff;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.rememberland-landing-gravyeards .rl-grave-fill-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rememberland-landing-gravyeards .rl-grave-fill .name {
  font-weight: 600;
  font-size: 24px;
}
.rememberland-landing-gravyeards .rl-grave-fill-bottom {
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.18);
  position: relative;
}
.rememberland-landing-gravyeards .rl-grave-fill-bottom-filled {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 8px;
  border-radius: 8px;
  background-color: #fff;
}
.rememberland-landing-gravyeards .rl-grave-publish-date {
  color: #fff;
  font-family: "Jost", sans-serif;
  font-weight: 700;
  opacity: 0;
}
/* .rememberland-landing-gravyeards .rl-animated-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
  margin-top: 24px;
} */
.rememberland-landing-gravyeards .swiper-slide {
  display: flex;
  flex-flow: column wrap;
}
.rememberland-landing-gravyeards .swiper-slide-active .rl-grave-fill,
.rememberland-landing-gravyeards .swiper-slide-active .rl-grave-publish-date {
  opacity: 1;
}
.rememberland-landing-gravyeards .swiper-button-next,
.rememberland-landing-gravyeards .swiper-button-prev {
  top: 40%;
}
@media (max-width: 48em) {
  .rememberland-landing-gravyeards {
    padding: 0 2rem;
  }
  .rememberland-landing-gravyeards .title {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1.75vw;
    margin-bottom: 1.5rem;
  }
  .rememberland-landing-gravyeards .desc {
    font-size: 15px;
    line-height: 21px;
    padding: 0 2rem;
    margin-bottom: 0;
    text-align: justify;
  }
  .rememberland-landing-gravyeards .desc br {
    display: none;
  }
}

.rl-graveyards-carousel .swiper-wrapper {
  padding: 40px 0;
}
.rl-graveyards-carousel .swiper-slide {
  transform: scale(0.5);
  transition: all 0.3s ease-out;
}
.rl-graveyards-carousel .swiper-slide-active {
  transform: scale(1);
}
@media (max-width: 64em) {
  .rl-graveyards-carousel .swiper-slide {
    transform: scale(0.85);
    transition: all 0.3s ease-out;
  }
  .rl-graveyards-carousel .swiper-slide-active {
    transform: scale(0.85);
  }
  .rl-graveyards-carousel a img,
  .rl-graveyards-carousel a video {
    margin: auto;
  }
  .rl-graveyards-carousel.swiper .swiper-pagination {
    margin-top: -16px;
  }
}
@media (max-width: 48em) {
  .rl-graveyards-carousel .swiper-wrapper {
    padding-top: 0;
  }
}

.rememberland-landing-details-for-life {
  padding: 150px 40px;
  text-align: center;
  color: #fff;
  font-family: "Jost", sans-serif;
}
.rememberland-landing-details-for-life .title {
  font-size: 65px;
  font-family: "Jost", sans-serif;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2vw;
  margin-bottom: 2rem;
}
@media (max-width: 48em) {
  .rememberland-landing-details-for-life {
    padding: 100px 2rem;
  }
  .rememberland-landing-details-for-life .title {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1.75vw;
    margin-bottom: 1.5rem;
  }
}

.rl-detail-for-life-desktop {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 48px;
}
.rl-detail-for-life-desktop-bttns {
  display: flex;
  gap: 60px;
}
.rl-detail-for-life-desktop-bttns a {
  opacity: 0.3;
  transition: all 0.3s ease-out;
}
.rl-detail-for-life-desktop-bttns a.active {
  opacity: 1;
}
.rl-detail-for-life-desktop-text {
  font-size: 24px;
  line-height: 36px;
  width: 78vw;
  margin-bottom: 60px;
}
.rl-detail-for-life-desktop-text .box {
  display: none;
  transition: all 0.3s ease-out;
}
.rl-detail-for-life-desktop-text .box.active {
  display: block;
  animation: fadeIn 1s;
}
.rl-detail-for-life-desktop-text h3 {
  font-weight: 700;
  font-size: 40px;
  font-family: "Jost", sans-serif;
  margin-bottom: 24px;
  letter-spacing: 1px;
}
@media (max-width: 48em) {
  .rl-detail-for-life-desktop {
    display: none;
  }
}

.rl-detail-for-life-mobile {
  display: none;
  margin: 0 0 24px 0;
}
.rl-detail-for-life-mobile .swiper-slide {
  opacity: 0.3;
}
.rl-detail-for-life-mobile .swiper-slide-active {
  opacity: 1;
}
.rl-detail-for-life-mobile h3 {
  font-family: "Jost", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin: 24px auto 16px;
  letter-spacing: 1px;
}
.rl-detail-for-life-mobile p {
  text-align: justify;
  font-size: 15px;
}
.rl-detail-for-life-mobile img {
  width: 250px;
  margin: auto;
}
@media (max-width: 48em) {
  .rl-detail-for-life-mobile {
    display: block;
  }
}

.rl-for-life-mobile-carousel-pagination {
  position: relative;
  bottom: auto;
  padding: 10px 0;
}

.rememberland-landing-guardians {
  padding: 150px 40px;
  text-align: center;
  color: #fff;
  font-family: "Jost", sans-serif;
}
.rememberland-landing-guardians .line-2 {
  font-size: 65px;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 8px;
}
.rememberland-landing-guardians .line-3 {
  font-size: 24px;
  line-height: 38px;
  width: 72vw;
  margin: 0 auto 40px;
  font-weight: 400;
}
.rememberland-landing-guardians .rl-guardians-carousel {
  max-width: 90vw;
  padding: 0 68px;
}
.rememberland-landing-guardians .rl-guardians-carousel a img {
  margin: 0 auto;
}
.rememberland-landing-guardians .rl-guardians-carousel .swiper-button-next,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-prev,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-button-prev,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-next {
  width: 60px;
  height: 60px;
  border: 1px solid;
  background: rgba(19, 19, 19, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 46%;
}
.rememberland-landing-guardians .rl-guardians-carousel .swiper-button-next:after,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-prev:after,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-button-prev:after,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-next:after {
  font-size: 28px;
}
.rememberland-landing-guardians .rl-guardians-carousel .swiper-button-next,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-prev {
  right: 0;
}
.rememberland-landing-guardians .rl-guardians-carousel .swiper-button-prev,
.rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-next {
  left: 0;
}
@media (max-width: 48em) {
  .rememberland-landing-guardians {
    padding: 100px 2rem;
  }
  .rememberland-landing-guardians .line-2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 16px;
    line-height: 1;
  }
  .rememberland-landing-guardians .line-3 {
    font-size: 15px;
    line-height: 22px;
    margin: 0 auto 30px;
  }
  .rememberland-landing-guardians .line-3 br {
    display: none;
  }
  .rememberland-landing-guardians .rl-guardians-carousel {
    max-width: 100vw;
    padding: 0;
  }
  .rememberland-landing-guardians .rl-guardians-carousel a img {
    margin: 0 auto;
  }
  .rememberland-landing-guardians .rl-guardians-carousel .swiper-button-next,
  .rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-prev,
  .rememberland-landing-guardians .rl-guardians-carousel .swiper-button-prev,
  .rememberland-landing-guardians .rl-guardians-carousel .swiper-rtl .swiper-button-next {
    display: none;
  }
}

.rememberland-landing-social-endorsement {
  text-align: center;
  padding: 0 48px 150px;
}
.rememberland-landing-social-endorsement .title {
  color: #fff;
  font-family: "Jost", sans-serif;
  font-size: 65px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 8px;
  line-height: 1;
  margin-bottom: 60px;
}
.rememberland-landing-social-endorsement .swiper-wrapper {
  display: grid;
  /*
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  */
  grid-auto-flow: column;
  padding-bottom: 60px;
}
.rememberland-landing-social-endorsement .swiper-wrapper .swiper-slide {
  display: flex;
  flex: 1;
  height: 100%;
}
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel {
  width: 90vw;
  padding: 0 120px;
  position: relative;
  margin: 0 auto;
}
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-next,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-prev,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-prev,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-next {
  width: 60px;
  height: 60px;
  border: 1px solid #FFE878;
  color: #FFE878;
  background: rgba(19, 19, 19, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 44%;
}
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-next:after,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-prev:after,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-prev:after,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-next:after {
  font-size: 28px;
}
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-next,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-prev {
  right: 0;
}
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-prev,
.rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-next {
  left: 0;
}
.rememberland-landing-social-endorsement .twitter-card {
  background-color: #fff;
  border: 1px solid #E9EBF3;
  padding: 40px 24px 10px;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
}
.rememberland-landing-social-endorsement .twitter-card-react {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: justify;
}
.rememberland-landing-social-endorsement .twitter-card .avatar {
  margin: 0 16px 0 0;
  width: 64px;
  height: 64px;
}
.rememberland-landing-social-endorsement .twitter-card .content {
  width: calc(100% - 80px);
  text-align: left;
}
.rememberland-landing-social-endorsement .twitter-card .name {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}
.rememberland-landing-social-endorsement .twitter-card .name span {
  color: #757683;
  margin-left: 4px;
}
.rememberland-landing-social-endorsement .twitter-card .text {
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 16px;
  height: 58px;
  overflow: hidden;
}
.rememberland-landing-social-endorsement .twitter-card a {
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  color: #1A1A1A;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 16px;
  border-radius: 100px;
  border: 0.5px solid #1A1A1A;
}
@media (max-width: 80em) {
  .rememberland-landing-social-endorsement {
    padding: 0 2rem 66px;
  }
  .rememberland-landing-social-endorsement .title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 32px;
  }
  .rememberland-landing-social-endorsement .swiper-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0;
    padding-bottom: 16px;
  }
  .rememberland-landing-social-endorsement .rl-social-endorsement-carousel {
    padding: 0;
    width: 100%;
  }
  .rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-next,
  .rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-prev,
  .rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-button-prev,
  .rememberland-landing-social-endorsement .rl-social-endorsement-carousel .swiper-rtl .swiper-button-next {
    display: none;
  }
  .rememberland-landing-social-endorsement .swiper-pagination {
    display: block;
  }
  .rememberland-landing-social-endorsement .twitter-card {
    width: 100%;
  }
  .rememberland-landing-social-endorsement .twitter-card a {
    margin-bottom: 40px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rememberland-hero-tabs-right .rememberland-list-group-item-content:not(.active) {
  display: none;
}

.rememberland-hero-tabs-images img:not(.active) {
  opacity: 0;
}

.rememberland-landing-hero-new {
  position: relative;
  height: 100vh;
}

.rememberland-hero-tabs-images {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.rememberland-hero-tabs-images img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.rememberland-hero-tabs-images img.active {
  opacity: 1;
}

.rememberland-hero-tabs {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 6vw;
  transform: translateY(-50%);
  display: flex;
  flex-flow: row wrap;
}
.rememberland-hero-tabs-outer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
}
@media (max-width: 48em) {
  .rememberland-hero-tabs-outer {
    display: none;
  }
}
.rememberland-hero-tabs-left {
  border-radius: 20px 0px 0px 20px;
  width: 120px;
  background-color: rgba(250, 250, 250, 0.8);
  padding: 48px 16px;
}
.rememberland-hero-tabs-left a {
  display: block;
  position: relative;
  color: #111;
  font-size: 14px;
  text-align: center;
  padding: 20px 0 16px;
}
.rememberland-hero-tabs-left a:not(:last-child) {
  margin-bottom: 10px;
}
.rememberland-hero-tabs-left a:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1.5px;
  background-color: #111;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-out;
}
.rememberland-hero-tabs-left a.active {
  font-weight: 600;
}
.rememberland-hero-tabs-left a.active:after {
  content: "";
  width: 48px;
}
.rememberland-hero-tabs-right {
  background-color: rgba(254, 254, 254, 0.9);
  width: 560px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 32px;
  border-radius: 0px 20px 20px 0px;
}
.rememberland-hero-tabs .rememberland-list-group-item-content {
  width: 100%;
  font-family: "Jost", sans-serif;
  transition: all 0.3s ease-out;
}
.rememberland-hero-tabs .rememberland-list-group-item-content.active {
  animation: fadeIn 1s;
  display: block;
}
.rememberland-hero-tabs .rememberland-list-group-item-content h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  font-family: "Jost", sans-serif;
  margin-bottom: 8px;
}
.rememberland-hero-tabs .rememberland-list-group-item-content p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 60px;
  margin-top: 16px;
}
.rememberland-hero-tabs .rememberland-list-group-item-content-links {
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.rememberland-hero-tabs .rememberland-list-group-item-content .black-bttn {
  background-color: #111;
  color: #fff;
  padding: 16px 24px;
  transition: all 0.3s ease-out;
}
.rememberland-hero-tabs .rememberland-list-group-item-content .black-bttn:hover {
  opacity: 0.6;
}
.rememberland-hero-tabs .rememberland-list-group-item-content .black-link {
  color: #111;
}
.rememberland-hero-tabs .rememberland-list-group-item-content .black-link-img {
  color: #111;
  display: contents;
}

.rl-hero-mobile-slider-outer {
  display: none;
}
@media (max-width: 48em) {
  .rl-hero-mobile-slider-outer {
    display: block;
    position: relative;
    height: 100vh;
  }
}

.rl-hero-mobile-slider .swiper-slide {
  width: 100%;
  height: 100vh;
  position: relative;
}
.rl-hero-mobile-slider-bg-img {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
  transform: translateX(-50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.rl-hero-mobile-slider-bg-img img {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
  transform: translateX(-50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.rl-hero-mobile-slider-bg-img:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(33, 9, 41, 0.6);
  backdrop-filter: blur(2.5px);
}
.rl-hero-mobile-slider-text {
  position: relative;
  margin-top: 25vh;
  z-index: 3;
  text-align: center;
  padding: 0 2rem;
  color: #fff;
  font-family: "Jost", sans-serif;
}
.rl-hero-mobile-slider-text h2 {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 11vw;
  line-height: 160%;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}
.rl-hero-mobile-slider-text p {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
}
.rl-hero-mobile-slider-text p span {
  font-weight: 400;
  font-size: 18px;
  display: block;
}
.rl-hero-mobile-slider-text .btn {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 24px;
  color: #FFF3B9;
  border: 1px solid #FFF3B9;
  background-color: rgba(17, 17, 17, 0.12);
  letter-spacing: 0.3em;
  margin-bottom: 16px;
}
.rl-hero-mobile-slider-text .read-more {
  display: block;
  color: #FFF3B9;
  font-size: 16px;
}
.rl-hero-mobile-slider .swiper-pagination {
  bottom: 20vh;
}

.rememberland-what-is {
  padding: 200px 2rem;
}
.rememberland-what-is-wrapper {
  margin: 0 auto;
  width: 86vw;
  display: flex;
  align-items: center;
  gap: 4rem;
  color: #fff;
  font-family: "Jost", sans-serif;
}
.rememberland-what-is .left,
.rememberland-what-is .right {
  width: 50%;
}
.rememberland-what-is h2 {
  font-family: "Jost", sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 60px;
  line-height: 58px;
  text-transform: uppercase;
}
.rememberland-what-is p {
  font-size: 24px;
  line-height: 36px;
}
@media (max-width: 48em) {
  .rememberland-what-is {
    padding: 60px 0 0;
  }
  .rememberland-what-is-wrapper {
    flex-flow: column wrap;
  }
  .rememberland-what-is .left,
  .rememberland-what-is .right {
    width: 100%;
  }
  .rememberland-what-is .left {
    padding: 0 2rem;
  }
  .rememberland-what-is h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 48px;
    margin-bottom: 24px;
  }
  .rememberland-what-is p {
    font-size: 15px;
    line-height: 22px;
  }
}

.rl-form-group {
  margin-bottom: 8px;
  position: relative;
}
.rl-form-group-material {
  position: relative;
}
.rl-form-group-material .rl-material-input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  padding: 18px 16px 8px 16px;
  height: 56px;
  border: 1px solid #CECED6;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
}
.rl-form-group-material .rl-material-input.rl-pass-w-icon {
  padding-right: 46px;
}
.rl-form-group-material.error .rl-material-input {
  border-color: #B65E49;
  background-color: #FFF3F3;
}
.rl-form-group-material.error .rl-material-label {
  z-index: 1;
}
.rl-form-group-material .rl-material-label {
  position: absolute;
  display: block;
  bottom: 20px;
  left: 16px;
  transition: all 200ms;
  font-size: 14px;
  color: #999AA7;
  z-index: 1;
  line-height: 1;
}
.rl-form-group-material .rl-icon-link {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.rl-form-group-material .rl-material-input:focus ~ .rl-material-label,
.rl-form-group-material .rl-material-input.focused ~ .rl-material-label {
  bottom: 32px;
  font-size: 12px;
  color: #646672;
}
.rl-form-group-material .rl-material-input:active,
.rl-form-group-material .rl-material-input:focus {
  cursor: text;
}
.rl-form-group .error-line {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-top: 4px;
  color: #B65E49;
}
.rl-form-group .error-line img {
  filter: invert(39%) sepia(83%) saturate(361%) hue-rotate(324deg) brightness(95%) contrast(93%);
  margin-right: 4px;
}

.rl-form-remember-forgot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.rl-forgot-pass {
  color: #1A1A1A;
  text-decoration: underline;
  font-size: 12px;
  line-height: 1;
}

.rl-form-button {
  text-align: center;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 0;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  background-color: #1A1A1A;
  color: #fff;
  transition: all 0.3s ease-out;
}
.rl-form-button.reset-pass-btn {
  margin-top: 32px;
}
.rl-form-button:hover {
  opacity: 0.6;
}

.rl-form-or {
  position: relative;
  margin: 32px 0;
  min-height: 1px;
  font-size: 10px;
}
.rl-form-or:before {
  content: "";
  height: 0.5px;
  width: 70%;
  background-color: #CECED6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.rl-form-or span {
  background-color: #fff;
  padding: 0 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #646672;
}

.rl-form-social-btns {
  /* display: grid;
  grid-template-columns: repeat(2, 11fr);
  gap: 8px; */
  margin-bottom: 32px;
}
.rl-form-social-btns a {
  font-size: 14px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #fff;
  transition: all 0.3s ease-out;
}
.rl-form-social-btns a img {
  margin-right: 10px;
}
.rl-form-social-btns a:hover {
  opacity: 0.8;
}
.rl-form-social-btns-google {
  background-color: #4E95EC;
}
.rl-form-social-btns-facebook {
  background-color: #425893;
}
.rl-form-social-btns-apple {
  background-color: #111;
  width: 246px;
  height: 48px;
}
.rl-form-social-btns-apple img{
  filter: invert(1);
  width: 25px;
  height: 25px;
}


.rl-sign-up-or-in {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  text-align: center;
  color: #646672;
}
.rl-sign-up-or-in a {
  color: #1A1A1A;
  font-weight: 600;
}

.rl-modal-img-top {
  margin: 0 auto 32px;
}

@media (max-width: 48em) {
  .rl-form-remember-forgot {
    margin-bottom: 24px;
  }
  .rl-form-or {
    margin: 24px 0;
  }
  .rl-form-social-btns a {
    font-size: 10px;
    padding: 13px 7px;
  }
  .rl-form-social-btns {
    margin-bottom: 24px;
  }
  .rl-form-button.reset-pass-btn {
    margin-top: 24px;
  }
}
.sign-in-white-outer {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: 140px 16px;
}
.sign-in-white-outer .rl-logo {
  margin: 0 auto 80px;
}
@media (max-width: 48em) {
  .sign-in-white-outer {
    padding: 80px 16px;
  }
  .sign-in-white-outer .rl-logo {
    margin: 0 auto 70px;
  }
}

.sign-in-white-box {
  border: 1px solid #CECED6;
  padding: 56px 96px;
  max-width: 700px;
  margin: auto;
  text-align: center;
}
.sign-in-white-box .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.sign-in-white-box .desc {
  font-size: 14px;
  margin-bottom: 40px;
}
@media (max-width: 48em) {
  .sign-in-white-box {
    padding: 56px 16px 40px;
  }
  .sign-in-white-box .title {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .sign-in-white-box .desc {
    font-size: 12px;
    margin-bottom: 24px;
  }

  .mobile-p-35px {
    padding: 35px !important;
  }
}

