.backdrop1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1000;
  padding: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modal {
  vertical-align: middle;
  position: relative;
  z-index: 9999;
  max-width: 564px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding: 72px 32px 40px;
  text-align: left;
  opacity: 1;
  display: inline-flex;
}

.modal.column {
  flex-flow: column wrap;
}

.modal-400 {
  max-width: 400px;
}

.modal a.close-modal {
  position: absolute;
  top: 32px;
  right: 32px;
  display: block;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
}

.modal a.close-modal:after {
  content: "×";
  position: absolute;
  top: 0;
  right: 0;
  text-indent: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #111;
}

.modal-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 16px;
}

.modal-header-desc {
  font-size: 14px;
  margin-bottom: 32px;
}

@media (max-width: 48em) {
  .modal {
    padding: 56px 16px 40px;
  }
  .modal a.close-modal {
    top: 16px;
    right: 16px;
  }
  .modal-header {
    font-size: 20px;
  }
  .modal-header-desc {
    font-size: 12px;
    margin-bottom: 24px;
  }
}

.loading-background {
  width: 121px;
  height: 121px;
  background-color: white;

  border: 1px solid transparent;
  border-radius: 24px;

  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
  position: fixed;
  display: flex;
  align-items: center;
}

.add-verse-img {
  margin: 0 auto 30px;
}
.rl-add-verse-modal {
  max-width: 65vw;
}
.rl-add-verse-modal h1 {
  font-size: 40px;
  margin-bottom: 20px;
}
.rl-add-verse-modal .modal-header-desc {
  max-width: 540px;
  margin: 0 auto 32px;
}
.rl-add-verse-modal .numbers span {
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 12px;
  margin-bottom: 20px;
  display: inline-block;
}
.rl-add-verse-modal .numbers span span {
  color: #757683;
  margin-bottom: 0;
}
.rl-add-verse-modal .numbers .rl-progress-bar {
  max-width: 300px;
  margin: 0 auto 24px;
}
@media (max-width: 48em) {
  .rl-add-verse-modal h1 {
    font-size: 24px;
  }
}

.text-center {
  text-align: center !important;
}

.rl-verse-modal {
  max-width: 65vw;
}
.rl-verse-modal h1 {
  font-size: 40px;
  margin-bottom: 20px;
}
.rl-verse-modal .modal-header-desc {
  max-width: 540px;
  margin: 0 auto 32px;
}
.rl-verse-modal .numbers span {
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 12px;
  margin-bottom: 20px;
  display: inline-block;
}
.rl-verse-modal .numbers span span {
  color: #757683;
  margin-bottom: 0;
}
.rl-verse-modal .numbers .rl-progress-bar {
  max-width: 300px;
  margin: 0 auto 24px;
}
@media (max-width: 48em) {
  .rl-verse-modal h1 {
    font-size: 24px;
  }
}
@media (max-width: 37.5em) {
  .rl-verse-modal {
    max-width: 90vw;
    padding: 56px 48px 40px;
  }
}

.w-100 {
  width: 100% !important;
}

.rememberland-modal-verse-video {
  max-width: 1280px !important
}

/* img, video {
  display: block;
  max-width: 100%;
  height: auto;
} */
