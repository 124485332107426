@charset "UTF-8";
/*
Description: Rememberland 2022
Version: 1.0.0
Author: Hakan Kamci
Author URI: hkamci@gmail.com
*/
img {
  display: block;
  max-width: 100%;
  height: auto;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
  position: relative;
}

/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
  content: attr(data-char);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  user-select: none;
}

/* Expanded CSS Variables */
.splitting {
  /* The center word index */
  --word-center: calc((var(--word-total) - 1) / 2);
  /* The center character index */
  --char-center: calc((var(--char-total) - 1) / 2);
  /* The center character index */
  --line-center: calc((var(--line-total) - 1) / 2);
}

.splitting .word {
  /* Pecent (0-1) of the word's position */
  --word-percent: calc(var(--word-index) / var(--word-total));
  /* Pecent (0-1) of the line's position */
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  /* Percent (0-1) of the char's position */
  --char-percent: calc(var(--char-index) / var(--char-total));
  /* Offset from center, positive & negative */
  --char-offset: calc(var(--char-index) - var(--char-center));
  /* Absolute distance from center, only positive */
  --distance: calc(
     (var(--char-offset) * var(--char-offset)) / var(--char-center)
  );
  /* Distance from center where -1 is the far left, 0 is center, 1 is far right */
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  /* Distance from center where 1 is far left/far right, 0 is center */
  --distance-percent: calc((var(--distance) / var(--char-center)));
}

.splitting {
  cursor: pointer;
}

/* ---------------------------------- */
.slide-vertical .char {
  overflow: hidden;
  color: transparent;
}
.slide-vertical .char:before, .slide-vertical .char:after {
  visibility: visible;
  color: #FFF;
  transition: transform 0.5s cubic-bezier(0.9, 0, 0.2, 1);
  transition-delay: calc(0.2s + 0.02s * (var(--char-index)));
}
.slide-vertical .char:before {
  color: #FFE878;
  transition-delay: calc(0.02s * (var(--char-index)));
}
.slide-vertical:hover .char:before {
  transition-delay: calc(0.2s + 0.02s * (var(--char-index)));
}
.slide-vertical:hover .char:after {
  transition-delay: calc(0.02s * (var(--char-index)));
}

.slide-vertical .char:before {
  transform: translateY(100%);
}
.slide-vertical:hover .char:before {
  transform: translateY(0%);
}
.slide-vertical:hover .char:after {
  transform: translateY(-100%);
}

.rememberland-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #302845;
  padding: 24px 24px 24px 48px;
  display: flex;
  align-items: center;
}
.rememberland-header .rememberland-logo-white {
  width: 200px;
  filter: invert(100%) sepia(8%) saturate(33%) hue-rotate(274deg) brightness(108%) contrast(107%);
}
.rememberland-header .mobile-hamburger {
  display: none;
  width: 24px;
}
.rememberland-header .mobile-hamburger img {
  filter: invert(100%) sepia(82%) saturate(1%) hue-rotate(136deg) brightness(107%) contrast(101%);
}
.rememberland-header .mobile-user-avatar {
  display: none;
}
.rememberland-header.rl-select-grave-type-header {
  background-color: rgba(255, 255, 255, 0.06);
}
.rememberland-header.white-header {
  background-color: #fff;
  border-bottom: 1px solid #E9EBF3;
}
.rememberland-header.white-header .rememberland-logo-white {
  filter: none;
}
.rememberland-header.white-header .mobile-hamburger img {
  filter: none;
}
.rememberland-header.white-header + .rememberland-nav-top .rl-lang img {
  filter: none;
}
.rememberland-header.white-header + .rememberland-nav-top .rememberland-header-right a {
  color: #1A1A1A !important;
}
.rememberland-header.white-header + .rememberland-nav-top .rememberland-header-right a.login {
  border-color: #1A1A1A;
}
.rememberland-header.white-header + .rememberland-nav-top .rememberland-header-right a.login img {
  filter: none;
}
.rememberland-header.white-header + .rememberland-nav-top .rememberland-header-right a.read-more {
  border-color: #1A1A1A;
}
.rememberland-header .rl-bo-user-mobile-left {
  width: 24px;
  height: 24px;
  display: none;
}
@media (max-width: 64em) {
  .rememberland-header {
    border-bottom: 1px solid rgba(255, 253, 239, 0.1);
    padding: 16px;
    z-index: 102;
    justify-content: space-between;
  }
  .rememberland-header .rememberland-logo-white {
    width: 156px;
  }
  .rememberland-header .mobile-hamburger {
    display: block;
  }
  .rememberland-header .mobile-user-avatar {
    display: block;
  }
  .rememberland-header .rl-bo-user-mobile-left {
    display: block;
  }
}

.rememberland-nav-top {
  position: fixed;
  top: 20px;
  right: 42px;
  z-index: 101;
  transition: all 0.3s ease-out;
}
.rememberland-nav-top.rl-bo-users-header {
  top: 34px;
}
.rememberland-nav-top .rememberland-header-right {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
}
.rememberland-nav-top .rememberland-logo-white {
  width: 156px;
  filter: invert(100%) sepia(8%) saturate(33%) hue-rotate(274deg) brightness(108%) contrast(107%);
}
.rememberland-nav-top .rememberland-header-right a {
  font-size: 12px;
  color: #fff;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-out;
}
.rememberland-nav-top .rememberland-header-right a:hover {
  opacity: 0.6;
}
.rememberland-nav-top .rememberland-header-right a.close-mobile-hamburger {
  display: none;
}
.rememberland-nav-top .rememberland-header-right a.login {
  border: 1px solid #fff;
  color: #fff;
  padding: 18px 24px;
  margin-left: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.rememberland-nav-top .rememberland-header-right a.login img {
  margin-right: 8px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(101%) contrast(103%);
}
.rememberland-nav-top .rememberland-header-right a.login .char:before {
  color: #fff !important;
}
.rememberland-nav-top .rememberland-header-right a.read-more {
  border: 1px solid #000;
  color: #000 !important;
  padding: 18px 24px;
  margin: 0 24px 0 16px;
  transition: all 0.3s ease-out;
}
.rememberland-nav-top .rememberland-header-right a.read-more .char:after {
  color: #EAD87F !important;
}
.rememberland-nav-top .rl-features {
  position: relative;
}
.rememberland-nav-top .rl-features .features-btn {
  margin: 0 24px 0 16px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
}
.rememberland-nav-top .rl-features .features-btn img {
  filter: invert(99%) sepia(6%) saturate(654%) hue-rotate(202deg) brightness(117%) contrast(100%);
  transition: all 0.3s ease-out;
}
.rememberland-nav-top .rl-features .features-btn.open img {
  transform: rotate(180deg);
}
.rememberland-nav-top .rl-features .rl-features-dropdown {
  position: absolute;
  right: 16px;
  z-index: 1;
  top: 22px;
  z-index: 10;
  background-color: rgba(254, 254, 254, 0.9);
  padding: 12px 16px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
}
.rememberland-nav-top .rl-features .rl-features-dropdown a {
  color: #111;
}
.rememberland-nav-top .rl-features .rl-features-dropdown.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.rememberland-nav-top .rl-features .rl-features-dropdown a {
  color: #111;
}
.rememberland-nav-top .rl-lang {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  padding: 18px 0;
  line-height: 1;
}
.rememberland-nav-top .rl-lang img {
  filter: invert(100%) sepia(8%) saturate(33%) hue-rotate(274deg) brightness(108%) contrast(107%);
  margin-left: 2px;
  transition: all 0.3s ease-out;
}
.rememberland-nav-top .rl-lang.open img {
  transform: rotate(180deg);
}
.rememberland-nav-top .rl-lang + .rl-lang-dropdown {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 10;
  background-color: #fff;
  padding: 12px 16px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
}
.rememberland-nav-top .rl-lang + .rl-lang-dropdown.show {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.rememberland-nav-top .rl-lang + .rl-lang-dropdown a {
  color: #111;
}
.rememberland-nav-top .rememberland-header-right-mobile-header {
  display: none;
}
@media (max-width: 64em) {
  .rememberland-nav-top {
    padding: 16px;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(-100%);
    transition: all 0.15s ease-out;
  }
  .rememberland-nav-top .rememberland-header-right {
    background: #1C1F37;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: calc(100vh - (100vh - 100%));
    height: 100svh;
    height: -webkit-fill-available;
    width: 100%;
    padding: 110px 24px 24px;
    transition: all 0.3s ease-out;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .rememberland-nav-top .rememberland-header-right a {
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
    margin-bottom: 16px;
    padding: 24px;
    font-size: 14px;
  }
  .rememberland-nav-top .rememberland-header-right a.close-mobile-hamburger {
    display: block;
  }
  .rememberland-nav-top .rememberland-header-right a.read-more, .rememberland-nav-top .rememberland-header-right a.login {
    color: #111;
    margin: 0 0 16px;
    padding: 24px;
  }
  .rememberland-nav-top .rememberland-header-right a.read-more:hover, .rememberland-nav-top .rememberland-header-right a.login:hover {
    background-color: transparent;
  }
  .rememberland-nav-top .rememberland-header-right .rl-lang-part {
    display: none;
  }
  .rememberland-nav-top .rememberland-header-right .rl-lang-part + .rl-lang-dropdown {
    position: absolute;
    bottom: 24px;
    left: 24px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    padding: 0;
    top: auto;
    right: auto;
  }
  .rememberland-nav-top .rememberland-header-right .rl-lang-part + .rl-lang-dropdown a {
    display: block;
    text-align: center;
  }
  .rememberland-nav-top .rememberland-header-right.show {
    left: 0;
  }
  .rememberland-nav-top .rememberland-header-right.show:before {
    content: "";
    position: absolute;
    left: 69.74%;
    right: -113.59%;
    top: -17.75%;
    bottom: 54.72%;
    background: linear-gradient(180deg, #755CDD 0%, #E4559A 100%);
    opacity: 0.45;
    filter: blur(100px);
  }
  .rememberland-nav-top .rememberland-header-right.show:after {
    content: "";
    position: absolute;
    left: -71.54%;
    right: 27.69%;
    top: 99.21%;
    bottom: -62.25%;
    background: linear-gradient(180deg, rgba(255, 228, 157, 0.6) 0%, #E4559A 100%);
    opacity: 0.3;
    filter: blur(100px);
  }
  .rememberland-nav-top .rememberland-header-right.show .rememberland-header-right-mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(255, 253, 239, 0.1);
  }
  .rememberland-nav-top .rememberland-header-right.show .rememberland-header-right-mobile-header a {
    margin: 0;
    padding: 0;
    width: auto;
  }
  .rememberland-nav-top .rememberland-header-right.show .rememberland-header-right-mobile-header .close-mobile-hamburger {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(102%) contrast(105%);
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part {
    display: block;
    position: absolute;
    left: 24px;
    bottom: 24px;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang {
    display: none;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang-dropdown {
    position: relative;
    bottom: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    padding: 0;
    top: auto;
    right: auto;
    background-color: transparent;
    display: flex;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang-dropdown a {
    color: rgba(255, 255, 255, 0.4);
    display: block;
    text-align: center;
    padding: 16px;
    margin: 0;
  }
  .rememberland-nav-top .rememberland-header-right.show .rl-lang-part .rl-lang-dropdown a.active {
    color: #fff;
  }
  .rememberland-nav-top.show {
    z-index: 103;
    right: 0;
    transform: translateX(0);
  }
}

.rememberland-footer {
  /* background: #151515 url("../img/landing-footer-bg-min.jpg") no-repeat top center; */
  background-color: #000;
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
  font-family: "Jost", sans-serif;
  font-weight: 500;
}
.rememberland-footer-left {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}
.rememberland-footer-left .logo {
  filter: invert(99%) sepia(12%) saturate(1318%) hue-rotate(306deg) brightness(98%) contrast(108%);
  width: 240px;
}
.rememberland-footer-left a {
  display: block;
  margin: 80px 0 110px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  text-decoration: underline;
}
.rememberland-footer-left div {
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
.rememberland-footer-right {
  text-align: right;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}
.rememberland-footer-right .button {
  border: 1px solid rgba(255, 243, 185, 0.17);
  color: #FFEBC4;
  display: inline-block;
  padding: 16px 32px 6px;
  margin-left: 10px;
  font-size: 18px;
  letter-spacing: 6px;
}
.rememberland-footer-right .bottom {
  margin-top: auto;
}
.rememberland-footer-right .bottom ul {
  display: flex;
  flex-flow: row wrap;
}
.rememberland-footer-right .bottom li:not(:last-child) {
  margin-right: 20px;
}
.rememberland-footer-right .bottom li {
  display: inline-block;
}
.rememberland-footer-right .bottom a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.rememberland-footer-right .bottom a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
}
.rememberland-footer-right .bottom img {
  filter: invert(92%) sepia(81%) saturate(388%) hue-rotate(317deg) brightness(98%) contrast(106%);
}
@media (max-width: 64em) {
  .rememberland-footer {
    display: none;
  }
}

.rememberland-panel-sidebar {
  padding: 48px 48px 24px;
  background: #1C1F37;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 91px;
  bottom: 0;
  height: calc(100vh - (100vh - 100%));
  height: 100svh;
  height: -webkit-fill-available;
  width: 420px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.14);
}
.rememberland-panel-sidebar:before {
  content: "";
  position: absolute;
  left: 69.74%;
  right: -113.59%;
  top: -17.75%;
  z-index: -1;
  bottom: 54.72%;
  background: linear-gradient(180deg, #755CDD 0%, #E4559A 100%);
  opacity: 0.45;
  filter: blur(100px);
}
.rememberland-panel-sidebar:after {
  content: "";
  position: absolute;
  left: -71.54%;
  right: 27.69%;
  top: 99.21%;
  bottom: -62.25%;
  z-index: -1;
  background: linear-gradient(180deg, rgba(255, 228, 157, 0.6) 0%, #E4559A 100%);
  opacity: 0.3;
  filter: blur(100px);
}
.rememberland-panel-sidebar a {
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar a:hover {
  opacity: 0.6;
}
.rememberland-panel-sidebar-footer {
  background: #1C1F37;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 24px 48px 0;
  border-top: 1px solid rgba(255, 253, 239, 0.1);
}
.rememberland-panel-sidebar-footer .read-more {
  border: 1px solid #EAD87F;
  color: #EAD87F !important;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}
.rememberland-panel-sidebar-footer .read-more img {
  filter: invert(89%) sepia(46%) saturate(476%) hue-rotate(336deg) brightness(94%) contrast(94%);
  margin-right: 10px;
}
.rememberland-panel-sidebar-footer .read-more.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
.rememberland-panel-sidebar-footer .rl-logout {
  color: #757683;
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.rememberland-panel-sidebar-footer .rl-logout img {
  margin-right: 10px;
  filter: invert(51%) sepia(13%) saturate(295%) hue-rotate(198deg) brightness(88%) contrast(94%);
}
.rememberland-panel-sidebar.bo-user .rememberland-panel-sidebar-footer {
  border-top: 0;
  background: transparent;
}
.rememberland-panel-sidebar.bo-user .rememberland-panel-sidebar-footer .rl-logout {
  padding-bottom: 48px;
}
.rememberland-panel-sidebar .bo-user-menu li {
  position: relative;
}
.rememberland-panel-sidebar .bo-user-menu li:not(:last-child) {
  margin-bottom: 8px;
}
.rememberland-panel-sidebar .bo-user-menu li:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../img/icon-chevron-right.svg") no-repeat center center;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  filter: invert(100%) sepia(0%) saturate(11%) hue-rotate(182deg) brightness(102%) contrast(105%);
}
.rememberland-panel-sidebar .bo-user-menu a {
  display: block;
  font-size: 16px;
  text-transform: none;
  text-align: left;
  letter-spacing: 0;
  color: #757683;
  margin: 0;
  padding: 18px 24px;
  line-height: 24px;
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar .bo-user-menu a:hover, .rememberland-panel-sidebar .bo-user-menu a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}
@media (max-width: 64em) {
  .rememberland-panel-sidebar {
    display: none;
  }
}

.rememberland-panel-sidebar-inner {
  display: flex;
  flex-flow: column wrap;
}
.rememberland-panel-sidebar-inner .rl-accordion {
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}
.rememberland-panel-sidebar-inner .select-desktop {
  display: block;
  width: 100%;
  padding: 20px 24px;
  height: 64px;
  cursor: pointer;
  position: relative;
  margin: 40px 0 20px;
}
.rememberland-panel-sidebar-inner .select-desktop_options {
  padding: 0 24px;
  border: 1px solid rgba(255, 255, 255, 0.47);
}
.rememberland-panel-sidebar-inner .select-desktop_expand {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.rememberland-panel-sidebar-inner .select-desktop_expand::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
  width: 32px;
  height: 32px;
  background: url("../img/icon-chevron-down.svg") no-repeat center center;
  transform-origin: center;
  filter: invert(99%) sepia(7%) saturate(25%) hue-rotate(278deg) brightness(106%) contrast(100%);
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:hover::after {
  opacity: 1;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked::after {
  transform: rotate(180deg);
}
.rememberland-panel-sidebar-inner .select-desktop_expandLabel {
  display: block;
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.rememberland-panel-sidebar-inner .select-desktop_close {
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 0px;
}
.rememberland-panel-sidebar-inner .select-desktop_input {
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  line-height: 64px;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.rememberland-panel-sidebar-inner .select-desktop_label-placeholder {
  height: 64px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  background-color: transparent;
}
.rememberland-panel-sidebar-inner .select-desktop_label img {
  margin-right: 10px;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked + .select-desktop_closeLabel + .select-desktop_options {
  background: #1C1F37;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked + .select-desktop_closeLabel + .select-desktop_options .select-desktop_label {
  height: 64px;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked + .select-desktop_closeLabel + .select-desktop_options + .select-desktop_expandLabel {
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_input:checked + .select-desktop_label {
  height: 64px;
}
.rememberland-panel-sidebar-inner .rl-create-new-grave {
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 16px;
  padding: 24px;
  font-size: 12px;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  border: 1px solid #EAD87F;
  color: #EAD87F !important;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-inner .rl-create-new-grave img {
  filter: invert(89%) sepia(46%) saturate(476%) hue-rotate(336deg) brightness(94%) contrast(94%);
  margin-right: 10px;
}

.rememberland-panel-sidebar-user {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.rememberland-panel-sidebar-user .rl-user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.29);
  object-fit: contain;
}
.rememberland-panel-sidebar-user .rl-user-settings {
  margin-left: auto;
}
.rememberland-panel-sidebar-user-name {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  margin-left: 8px;
}
.rememberland-panel-sidebar-user-name span {
  font-weight: 400;
}
.rememberland-panel-sidebar-user a {
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar-user a:hover {
  opacity: 0.6;
}

.rememberland-panel-sidebar-mobile {
  background: #1C1F37;
  z-index: 123;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: calc(100vh - (100vh - 100%));
  height: 100svh;
  height: -webkit-fill-available;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}
.rememberland-panel-sidebar-mobile:before {
  content: "";
  position: absolute;
  left: 69.74%;
  right: -113.59%;
  top: -17.75%;
  z-index: -1;
  bottom: 54.72%;
  background: linear-gradient(180deg, #755CDD 0%, #E4559A 100%);
  opacity: 0.45;
  filter: blur(100px);
}
.rememberland-panel-sidebar-mobile:after {
  content: "";
  position: absolute;
  left: -71.54%;
  right: 27.69%;
  top: 99.21%;
  bottom: -62.25%;
  z-index: -1;
  background: linear-gradient(180deg, rgba(255, 228, 157, 0.6) 0%, #E4559A 100%);
  opacity: 0.3;
  filter: blur(100px);
}
.rememberland-panel-sidebar-mobile.show {
  opacity: 1;
  pointer-events: all;
}
.rememberland-panel-sidebar-mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 253, 239, 0.1);
}
.rememberland-panel-sidebar-mobile-header .rememberland-logo-white {
  width: 156px;
  filter: invert(100%) sepia(8%) saturate(33%) hue-rotate(274deg) brightness(108%) contrast(107%);
}
.rememberland-panel-sidebar-mobile-header .close-mobile-hamburger {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(102%) contrast(105%);
}
.rememberland-panel-sidebar-mobile-header .rl-bo-user-mobile-left {
  width: 24px;
  height: 24px;
}
.rememberland-panel-sidebar-mobile-body {
  padding: 20px 24px 90px;
  overflow-y: auto;
  height: calc(100% - 72px);
}
.rememberland-panel-sidebar-mobile-body a {
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 16px;
  padding: 24px;
  font-size: 12px;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}
.rememberland-panel-sidebar-mobile-body a.read-more, .rememberland-panel-sidebar-mobile-body a.login {
  border: 1px solid #EAD87F;
  color: #EAD87F !important;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-mobile-body a.read-more img, .rememberland-panel-sidebar-mobile-body a.login img {
  filter: invert(89%) sepia(46%) saturate(476%) hue-rotate(336deg) brightness(94%) contrast(94%);
  margin-right: 10px;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu li {
  position: relative;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu li:not(:last-child) {
  margin-bottom: 8px;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu li:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../img/icon-chevron-right.svg") no-repeat center center;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  filter: invert(100%) sepia(0%) saturate(11%) hue-rotate(182deg) brightness(102%) contrast(105%);
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu a {
  font-size: 16px;
  text-transform: none;
  text-align: left;
  letter-spacing: 0;
  color: #757683;
  margin: 0;
  padding: 18px 24px;
  line-height: 24px;
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu a:hover, .rememberland-panel-sidebar-mobile-body .bo-user-menu a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}
.rememberland-panel-sidebar-mobile .select {
  display: block;
  width: 100%;
  padding: 20px 24px;
  height: 64px;
  cursor: pointer;
  position: relative;
  margin: 40px 0 20px;
}
.rememberland-panel-sidebar-mobile .select_options {
  padding: 0 24px;
  border: 1px solid rgba(255, 255, 255, 0.47);
  background: #1C1F37;
}
.rememberland-panel-sidebar-mobile .select_expand {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.rememberland-panel-sidebar-mobile .select_expand::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
  width: 32px;
  height: 32px;
  background: url("../img/icon-chevron-down.svg") no-repeat center center;
  transform-origin: center;
  filter: invert(99%) sepia(7%) saturate(25%) hue-rotate(278deg) brightness(106%) contrast(100%);
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-mobile .select_expand:hover::after {
  opacity: 1;
}
.rememberland-panel-sidebar-mobile .select_expand:checked::after {
  transform: rotate(180deg);
}
.rememberland-panel-sidebar-mobile .select_expandLabel {
  display: block;
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.rememberland-panel-sidebar-mobile .select_close {
  display: none;
}
.rememberland-panel-sidebar-mobile .select_closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}
.rememberland-panel-sidebar-mobile .select_items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 0px;
}
.rememberland-panel-sidebar-mobile .select_input {
  display: none;
}
.rememberland-panel-sidebar-mobile .select_label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  line-height: 64px;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.rememberland-panel-sidebar-mobile .select_label-placeholder {
  height: 64px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  background-color: transparent;
}
.rememberland-panel-sidebar-mobile .select_label img {
  margin-right: 10px;
}
.rememberland-panel-sidebar-mobile .select_expand:checked + .select_closeLabel + .select_options .select_label {
  height: 64px;
}
.rememberland-panel-sidebar-mobile .select_expand:checked + .select_closeLabel + .select_options + .select_expandLabel {
  display: none;
}
.rememberland-panel-sidebar-mobile .select_input:checked + .select_label {
  height: 64px;
}
.rememberland-panel-sidebar-mobile-footer {
  background: #1C1F37;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 24px;
  border-top: 1px solid rgba(255, 253, 239, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rememberland-panel-sidebar-mobile-footer .rl-lang {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 10px;
}
.rememberland-panel-sidebar-mobile-footer .rl-lang a {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 4px;
  padding: 8px;
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar-mobile-footer .rl-lang a:hover, .rememberland-panel-sidebar-mobile-footer .rl-lang a.active {
  color: #fff;
}
.rememberland-panel-sidebar-mobile-footer .rl-logout {
  color: #757683;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.rememberland-panel-sidebar-mobile-footer .rl-logout img {
  margin-right: 10px;
  filter: invert(51%) sepia(13%) saturate(295%) hue-rotate(198deg) brightness(88%) contrast(94%);
}

.rl-accordion .inner {
  display: none;
}
.rl-accordion li a {
  display: block;
  padding: 20px 24px;
  position: relative;
  color: #757683;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0;
  text-transform: none;
  transition: all 0.3s ease-out;
}
.rl-accordion li a:hover {
  opacity: 0.6;
}
.rl-accordion li a:after {
  content: "";
  background: url("../img/icon-chevron-down.svg") no-repeat center center;
  transform-origin: center;
  filter: invert(99%) sepia(7%) saturate(25%) hue-rotate(278deg) brightness(106%) contrast(100%);
  position: absolute;
  right: 18px;
  top: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.25;
  transition: all 0.3s ease-out;
}
.rl-accordion li.active a {
  color: #fff;
}
.rl-accordion li.active a:after {
  transform: rotate(180deg);
  transform-origin: center center;
  opacity: 1;
}
.rl-accordion .inner {
  padding-bottom: 1rem;
  text-align: left;
}
.rl-accordion .inner ul {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  list-style-position: inside;
  margin-left: 24px;
  padding-left: 20px;
}
.rl-accordion .inner ul a {
  color: #757683;
  padding: 16px 24px;
  font-size: 12px;
  transition: all 0.3s ease-out;
}
.rl-accordion .inner ul a:hover, .rl-accordion .inner ul a.active {
  background-color: rgba(255, 255, 255, 0.08);
  color: #fff;
}
.rl-accordion .inner ul a::after {
  content: "";
  background: none;
}

.rl-custom-scroll {
  scrollbar-color: #fff #646672;
  scrollbar-width: thin;
  overflow-y: auto;
}

.rl-custom-scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  border-radius: 100px;
}

.rl-custom-scroll::-webkit-scrollbar-track {
  background: rgba(100, 102, 114, 0.1);
  border-radius: 100px;
}

.rl-custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(100, 102, 114, 0.15);
  border-radius: 100px;
}

.rl-custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #757683;
}

.rl-toast-message {
  padding: 24px 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #1A1A1A;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  position: fixed;
  right: 30px;
  bottom: 110px;
  z-index: 1000;
}
.rl-toast-message img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.rl-toast-message.green {
  border-color: #56BB67;
  background-color: #EAF7EE;
}
.rl-toast-message.green img {
  filter: invert(64%) sepia(55%) saturate(421%) hue-rotate(78deg) brightness(89%) contrast(88%);
}
.rl-toast-message.red {
  border-color: #b65e49;
  background-color: #FFF3F3;
}
.rl-toast-message.red img {
  filter: invert(44%) sepia(28%) saturate(1357%) hue-rotate(324deg) brightness(88%) contrast(70%);
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

iframe,
object,
embed {
  max-width: 100%;
  display: block;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rl-loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.rl-loading-icon-bg {
  width: 124px;
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 24px;
}
.rl-loading .rl-lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.rl-loading .rl-lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.rl-loading .rl-lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 16px;
  background: #000;
}
.rl-loading .rl-lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.rl-loading .rl-lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.rl-loading .rl-lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.rl-loading .rl-lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.rl-loading .rl-lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.rl-loading .rl-lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.rl-loading .rl-lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.rl-loading .rl-lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.rl-loading .rl-lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.rl-loading .rl-lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.rl-loading .rl-lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.rl-loading .rl-lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/**/
.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-flex.row {
  flex-flow: row wrap;
}

.d-flex.col {
  flex-flow: column wrap;
}

.d-flex.column {
  flex-flow: column wrap;
}

.d-flex.align-center {
  align-items: center;
}

.d-flex.align-start {
  align-items: flex-start;
}

.d-flex.align-end {
  align-items: flex-end;
}

.d-flex.justify-center {
  justify-content: center;
}

.d-flex.justify-space {
  justify-content: space-between;
}

.d-flex.justify-around {
  justify-content: space-around;
}

.d-flex.justify-end {
  justify-content: flex-end;
}

.d-flex.justify-start {
  justify-content: flex-start;
}

.flex-grow-vert {
  flex: 1 0 auto;
}

.align-self-start {
  align-self: flex-start;
}

.global-col-1 {
  width: 100%;
}

.global-col-2 {
  width: 50%;
}
@media (max-width: 48em) {
  .global-col-2 {
    width: 100%;
    margin: 0;
  }
}

.global-col-3 {
  width: 33.3333333333%;
}
@media (max-width: 48em) {
  .global-col-3 {
    width: 100%;
  }
}

.global-col-1-3 {
  width: 33.3333333333%;
}
@media (max-width: 37.5em) {
  .global-col-1-3 {
    width: 100%;
  }
}

.global-col-2-3 {
  width: 66.6666666667%;
}
@media (max-width: 37.5em) {
  .global-col-2-3 {
    width: 100%;
  }
}

.global-col-1-4 {
  width: 25%;
}
@media (max-width: 37.5em) {
  .global-col-1-4 {
    width: 100%;
  }
}

.global-col-3-4 {
  width: 75%;
}
@media (max-width: 37.5em) {
  .global-col-3-4 {
    width: 100%;
  }
}

.global-col-7 {
  width: 58.3333333333%;
}
@media (max-width: 37.5em) {
  .global-col-7 {
    width: 100%;
  }
}

.global-col-5 {
  width: 41.6666666667%;
}
@media (max-width: 37.5em) {
  .global-col-5 {
    width: 100%;
  }
}

.flex-1 {
  flex: 1;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}
@media (max-width: 48em) {
  .w-50 {
    width: 100% !important;
  }
}

.w-30 {
  width: 30% !important;
}

.min-height-100 {
  min-height: 100vh;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.form-select {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  padding: 18px 30px 8px 16px;
  height: 56px;
  border: 1px solid #CECED6;
  font-size: 14px;
  cursor: pointer;
}
.form-select:after {
  color: #000;
  content: "▾";
  display: block;
  height: 14px;
  position: absolute;
  right: 24px;
  top: 40px;
  width: 14px;
  z-index: 1;
}
.form-select-label {
  position: absolute;
  display: block;
  bottom: 32px;
  left: 16px;
  transition: all 200ms;
  font-size: 12px;
  color: #646672;
  z-index: 1;
  line-height: 1;
}

.form-checkbox-outer label {
  display: flex;
  align-items: center;
  font-size: 10px;
}

.form-radio,
.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #fff;
  border: thin solid #1A1A1A;
  color: #1A1A1A;
  height: 16px;
  width: 16px;
  border-radius: 0;
  cursor: pointer;
  margin: 0 7px 0 0;
  outline: none;
  transition: all 0.3s ease-out;
}
.form-radio:checked::before,
.form-checkbox:checked::before {
  position: absolute;
  left: 4px;
  top: 1px;
  content: "⅃";
  transform: rotate(40deg);
  font-size: 9px;
}
.form-radio:checked,
.form-checkbox:checked {
  background-color: #1A1A1A;
  border-color: #1A1A1A;
  color: #fff;
  z-index: 2;
}

.form-radio {
  border: 1px solid #1A1A1A;
  border-radius: 50%;
  top: 6px;
  height: 24px;
  width: 24px;
  transition: all 0.3s ease-out;
}
.form-radio:before {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "";
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-out;
}
.form-radio:checked {
  background-color: transparent;
  border-color: #1A1A1A;
  color: #fff;
  z-index: 2;
  transition: all 0.3s ease-out;
}
.form-radio:checked:before {
  content: "";
  background: #1A1A1A;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form-checkbox {
  border-radius: 3px;
}

.error-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #B65E49;
  margin-top: 0.25rem;
}

button {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
button:focus {
  outline: 0;
}

.file-upload {
  position: relative;
  display: inline-block;
}
.file-upload .file-upload__label {
  font-weight: 600;
  color: #1A1A1A;
  display: block;
  padding: 12px 40px;
  border: 1px solid;
}
.file-upload .file-upload__label.disabled {
  color: #EAEBF2;
}
.file-upload .file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
}
.file-upload .file-upload__input input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
}
.file-upload.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.file-upload-mobile {
  position: relative;
  display: inline-block;
}
.file-upload-mobile .file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
}
.file-upload-mobile .file-upload__input input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
}

.rl-form-group {
  margin-bottom: 8px;
  position: relative;
}
.rl-form-group-material {
  position: relative;
}
.rl-form-group-material .rl-material-input,
.rl-form-group-material input[type=date] {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  padding: 18px 16px 8px 16px;
  height: 56px;
  border: 1px solid #CECED6;
  font-size: 14px;
  background: transparent;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}
.rl-form-group-material .rl-material-input.rl-pass-w-icon,
.rl-form-group-material input[type=date].rl-pass-w-icon {
  padding-right: 46px;
}
.rl-form-group-material.error .rl-material-input {
  border-color: #B65E49;
  background-color: #FFF3F3;
}
.rl-form-group-material.error .rl-material-label {
  z-index: 1;
}
.rl-form-group-material .rl-material-label {
  position: absolute;
  display: block;
  top: 22px;
  left: 16px;
  transition: all 200ms;
  font-size: 14px;
  color: #999AA7;
  z-index: 1;
  line-height: 1;
}
.rl-form-group-material .rl-icon-link {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.rl-form-group-material .rl-material-input:focus ~ .rl-material-label,
.rl-form-group-material .rl-material-input.focused ~ .rl-material-label,
.rl-form-group-material .rl-material-input[type=date] ~ .rl-material-label,
.rl-form-group-material select ~ .rl-material-label {
  top: 10px;
  font-size: 12px;
  color: #646672;
}
.rl-form-group-material .rl-material-input:active,
.rl-form-group-material .rl-material-input:focus {
  cursor: text;
}
.rl-form-group input[type=date] {
  user-select: none;
}
.rl-form-group select.rl-material-input {
  position: relative;
  appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6 9L12 15L18 9' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 16px;
  padding-right: 40px;
}
.rl-form-group textarea {
  font-family: "Inter", sans-serif;
  height: 180px;
  resize: none;
}
.rl-form-group textarea.rl-material-input {
  height: 180px;
  padding-top: 26px;
}
.rl-form-group .select2-container {
  min-width: 100%;
}
.rl-form-group .select2-container--default .select2-selection--multiple {
  min-height: 56px;
  border-radius: 0;
  border-color: #CECED6;
  padding: 20px 40px 8px 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6 9L12 15L18 9' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 16px;
}
.rl-form-group .select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
}
.rl-form-group .select2-container--default .select2-selection--multiple .select2-selection__choice {
  z-index: 2;
}
.rl-form-group .error-line {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-top: 4px;
  color: #B65E49;
}
.rl-form-group .error-line img {
  filter: invert(39%) sepia(83%) saturate(361%) hue-rotate(324deg) brightness(95%) contrast(93%);
  margin-right: 4px;
}
.rl-form-group .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  left: auto;
  right: 0;
  border: 1px solid;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  top: 3px;
  right: 4px;
  color: #646672;
  line-height: 1;
}
.rl-form-group .select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 2px 20px 3px 4px;
  font-size: 12px;
  background-color: #EAEBF2;
  border: 0;
}

.rl-form-remember-forgot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.rl-forgot-pass {
  color: #1A1A1A;
  text-decoration: underline;
  font-size: 12px;
  line-height: 1;
}

.rl-form-button {
  text-align: center;
  display: block;
  width: 100%;
  height: 56px;
  cursor: pointer;
  border: 0;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  background-color: #1A1A1A;
  color: #fff;
  transition: all 0.3s ease-out;
}
.rl-form-button.reset-pass-btn {
  margin-top: 32px;
}
.rl-form-button:hover {
  opacity: 0.6;
}

.rl-content-table-select {
  font-weight: 600;
  outline: 0;
  border: 1px solid #fff;
  padding: 10px;
  min-width: 120px;
  width: 100%;
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1000;
  padding: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 564px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  padding: 72px 32px 40px;
  text-align: left;
}
.modal.column {
  flex-flow: column wrap;
}
.modal-400 {
  max-width: 400px;
}

.modal a.close-modal {
  position: absolute;
  top: 32px;
  right: 32px;
  display: block;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
}
.modal a.close-modal:hover {
  opacity: 0.6;
}
.modal a.close-modal:after {
  content: "×";
  position: absolute;
  top: 0;
  right: 0;
  text-indent: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #111;
}

.modal-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 16px;
}
.modal-header-desc {
  font-size: 14px;
  margin-bottom: 32px;
}

@media (max-width: 48em) {
  .modal {
    padding: 56px 16px 40px;
  }
  .modal a.close-modal {
    top: 16px;
    right: 16px;
  }
  .modal-header {
    font-size: 20px;
  }
  .modal-header-desc {
    font-size: 12px;
    margin-bottom: 24px;
  }
}
.modal-2-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
@media (max-width: 37.5em) {
  .modal-2-buttons {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.rl-btn-border {
  border: 1px solid;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.rl-btn-border:hover {
  opacity: 0.6;
}
.rl-btn-border.black {
  border-color: #1A1A1A;
  color: #1A1A1A;
}

.rl-btn-filled {
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.rl-btn-filled:hover {
  opacity: 0.6;
}
.rl-btn-filled.black {
  background-color: #1A1A1A;
  color: #fff;
}
.rl-btn-filled.w-220 {
  width: 220px;
}

.add-verse-img {
  margin: 0 auto 30px;
}

.rl-add-verse-modal {
  max-width: 65vw;
}
.rl-add-verse-modal h1 {
  font-size: 40px;
  margin-bottom: 20px;
}
.rl-add-verse-modal .modal-header-desc {
  max-width: 540px;
  margin: 0 auto 32px;
}
.rl-add-verse-modal .numbers span {
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 12px;
  margin-bottom: 20px;
  display: inline-block;
}
.rl-add-verse-modal .numbers span span {
  color: #757683;
  margin-bottom: 0;
}
.rl-add-verse-modal .numbers .rl-progress-bar {
  max-width: 300px;
  margin: 0 auto 24px;
}
@media (max-width: 48em) {
  .rl-add-verse-modal {
    max-width: 90vw;
  }
  .rl-add-verse-modal h1 {
    font-size: 24px;
  }
}

.rl-modal-change-verse {
  max-width: 80vw;
}
.rl-modal-change-verse .rl-list-all-verses-grid {
  margin-bottom: 60px;
}
.rl-modal-change-verse .rl-list-all-verses-grid .rl-select-verse-btn.active {
  color: #56BB67;
}
.rl-modal-change-verse .rl-list-all-verses-grid .rl-select-verse-btn.active img {
  filter: invert(71%) sepia(13%) saturate(1719%) hue-rotate(78deg) brightness(89%) contrast(87%);
}
@media (max-width: 48em) {
  .rl-modal-change-verse {
    max-width: initial;
  }
  .rl-modal-change-verse .rl-list-all-verses-grid {
    margin-bottom: 32px;
  }
}

.rl-table-outer {
  overflow-x: auto;
  width: 100%;
}
.rl-table-outer.rl-custom-scroll {
  padding-bottom: 10px;
}

.rl-table-admin-bottom {
  padding: 24px;
  width: 100%;
  word-break: keep-all;
  border-collapse: collapse;
}
.rl-table-admin-bottom tr {
  margin: 10px 0;
}
.rl-table-admin-bottom tr .form-checkbox {
  margin: 8px 0 0 0;
}
.rl-table-admin-bottom thead {
  position: relative;
  margin-bottom: 10px;
}
.rl-table-admin-bottom thead tr th {
  padding: 10px 24px;
  text-align: left;
  font-size: 12px;
  color: #757683;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  word-break: keep-all;
  white-space: nowrap;
  vertical-align: middle;
}
.rl-table-admin-bottom tbody {
  border: 1px solid #E9EBF3;
}
.rl-table-admin-bottom tbody tr td {
  padding: 16px 24px;
  word-break: keep-all;
  white-space: nowrap;
  vertical-align: middle;
}
.rl-table-admin-bottom tbody tr:nth-child(even) {
  background-color: #F8F9FC;
}
.rl-table-admin-bottom tbody tr.error {
  background-color: #FFF3F3;
}
.rl-table-admin-bottom .rl-table-border-l {
  position: relative;
}
.rl-table-admin-bottom .rl-table-border-l:before {
  content: "";
  position: absolute;
  left: 0;
  height: 20px;
  width: 1px;
  background: #ccc;
  top: 50%;
  transform: translateY(-50%);
}
.rl-table-admin-bottom .w-40 {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  gap: 16px;
  min-width: 360px;
}
.rl-table-admin-bottom .buttons-4 {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  gap: 16px;
  min-width: 436px;
}
.rl-table-admin-bottom .no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 200px;
  font-weight: 600;
  font-size: 24px;
  padding: 20px;
}
@media (max-width: 48em) {
  .rl-table-admin-bottom .no-content {
    font-size: 14px;
  }
}
.rl-table-admin-bottom .w-30 {
  min-width: 200px;
}
.rl-table-admin-bottom-filters {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #E9EBF3;
}
.rl-table-admin-bottom .rl-table-download-btn {
  display: flex;
  align-items: center;
  letter-spacing: 0;
  font-size: 12px;
  color: #1A1A1A;
  text-transform: none;
}
.rl-table-admin-bottom .rl-table-download-btn img {
  margin-right: 4px;
}
.rl-table-admin-bottom .rl-table-download-btn.disabled {
  opacity: 0.6;
  pointer-events: none;
}
@media (max-width: 80em) {
  .rl-table-admin-bottom-filters {
    grid-template-columns: 1fr !important;
  }
}

.rl-table-button {
  display: inline-block;
  padding: 12px 24px;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 600;
  color: #1A1A1A;
  letter-spacing: 0;
  text-transform: none;
  transition: all 0.3s ease-out;
}
.rl-table-button:hover {
  opacity: 0.6;
}
.rl-table-button.red {
  border-color: #B65E49;
  background-color: #FFF3F3;
}
.rl-table-button.blue {
  border-color: #44AFC7;
  background-color: #E4FAFF;
}
.rl-table-button.light-gray {
  background-color: #EAEBF2;
}
.rl-table-button.disabled {
  background-color: #EAEBF2;
  color: #757683;
  cursor: default;
}

.rl-link-black {
  color: #1A1A1A;
  font-size: 12px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  width: max-content;
  transition: all 0.3s ease-out;
}
.rl-link-black img {
  margin-right: 4px;
}
.rl-link-black:hover {
  opacity: 0.6;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}
html.html-no-scroll {
  overflow-y: hidden;
  overscroll-behavior: none;
}

body {
  background-color: #231B39;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
body:before {
  content: "";
  position: fixed;
  width: 1025px;
  height: 1025px;
  left: 60%;
  top: -27%;
  z-index: -1;
  background: linear-gradient(180deg, #755CDD 0%, #E4559A 100%);
  mix-blend-mode: hard-light;
  opacity: 0.2;
  filter: blur(100px);
}
body.bg-white:before {
  display: none;
}
body.body-no-scroll {
  overflow: hidden;
  overscroll-behavior: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Inter", serif;
  line-height: 1.3;
}

h1,
.h1 {
  font-size: 24px;
  line-height: 29.05px;
}

h2,
.h2 {
  font-size: 20px;
  line-height: 24.2px;
}

p {
  margin-bottom: 1.5rem;
}

i {
  font-style: italic;
}

b {
  font-weight: bold;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.bold,
strong {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-not-uppercase {
  text-transform: initial !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.cursor-default {
  cursor: default !important;
}

ul.unordered {
  list-style: disc;
  list-style-position: inside;
}
ul.unordered li {
  margin-bottom: 10px;
}

ol.ordered {
  list-style: decimal;
  list-style-position: inside;
}
ol.ordered li {
  margin-bottom: 20px;
}

.text-404 {
  font-size: 18rem;
  font-weight: 400;
  color: #fff;
}
@media (max-width: 48em) {
  .text-404 {
    font-size: 10rem;
  }
}

.lh-1 {
  line-height: 1;
}

.lh-1-3 {
  line-height: 1.3;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.str-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.op-50 {
  opacity: 0.5;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.font-body {
  font-family: "Inter", sans-serif;
}

.font-header {
  font-family: "Inter", serif;
}

.secondary-text {
  font-weight: 400;
  color: #646672;
}

.text-12 {
  font-size: 12px;
}

.rememberland-panel-sidebar {
  padding: 48px 48px 24px;
  background: #1C1F37;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 91px;
  bottom: 0;
  height: calc(100vh - (100vh - 100%));
  height: 100svh;
  height: -webkit-fill-available;
  width: 420px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.14);
}
.rememberland-panel-sidebar:before {
  content: "";
  position: absolute;
  left: 69.74%;
  right: -113.59%;
  top: -17.75%;
  z-index: -1;
  bottom: 54.72%;
  background: linear-gradient(180deg, #755CDD 0%, #E4559A 100%);
  opacity: 0.45;
  filter: blur(100px);
}
.rememberland-panel-sidebar:after {
  content: "";
  position: absolute;
  left: -71.54%;
  right: 27.69%;
  top: 99.21%;
  bottom: -62.25%;
  z-index: -1;
  background: linear-gradient(180deg, rgba(255, 228, 157, 0.6) 0%, #E4559A 100%);
  opacity: 0.3;
  filter: blur(100px);
}
.rememberland-panel-sidebar a {
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar a:hover {
  opacity: 0.6;
}
.rememberland-panel-sidebar-footer {
  background: #1C1F37;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 24px 48px 0;
  border-top: 1px solid rgba(255, 253, 239, 0.1);
}
.rememberland-panel-sidebar-footer .read-more {
  border: 1px solid #EAD87F;
  color: #EAD87F !important;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}
.rememberland-panel-sidebar-footer .read-more img {
  filter: invert(89%) sepia(46%) saturate(476%) hue-rotate(336deg) brightness(94%) contrast(94%);
  margin-right: 10px;
}
.rememberland-panel-sidebar-footer .rl-logout {
  color: #757683;
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.rememberland-panel-sidebar-footer .rl-logout img {
  margin-right: 10px;
  filter: invert(51%) sepia(13%) saturate(295%) hue-rotate(198deg) brightness(88%) contrast(94%);
}
.rememberland-panel-sidebar.bo-user .rememberland-panel-sidebar-footer {
  border-top: 0;
  background: transparent;
}
.rememberland-panel-sidebar.bo-user .rememberland-panel-sidebar-footer .rl-logout {
  padding-bottom: 48px;
}
.rememberland-panel-sidebar .bo-user-menu li {
  position: relative;
}
.rememberland-panel-sidebar .bo-user-menu li:not(:last-child) {
  margin-bottom: 8px;
}
.rememberland-panel-sidebar .bo-user-menu li:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../img/icon-chevron-right.svg") no-repeat center center;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  filter: invert(100%) sepia(0%) saturate(11%) hue-rotate(182deg) brightness(102%) contrast(105%);
}
.rememberland-panel-sidebar .bo-user-menu a {
  display: block;
  font-size: 16px;
  text-transform: none;
  text-align: left;
  letter-spacing: 0;
  color: #757683;
  margin: 0;
  padding: 18px 24px;
  line-height: 24px;
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar .bo-user-menu a:hover, .rememberland-panel-sidebar .bo-user-menu a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}
@media (max-width: 64em) {
  .rememberland-panel-sidebar {
    display: none;
  }
}

.rememberland-panel-sidebar-inner {
  display: flex;
  flex-flow: column wrap;
}
.rememberland-panel-sidebar-inner .rl-accordion {
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}
.rememberland-panel-sidebar-inner .select-desktop {
  display: block;
  width: 100%;
  padding: 20px 24px;
  height: 64px;
  cursor: pointer;
  position: relative;
  margin: 40px 0 20px;
}
.rememberland-panel-sidebar-inner .select-desktop_options {
  padding: 0 24px;
  border: 1px solid rgba(255, 255, 255, 0.47);
}
.rememberland-panel-sidebar-inner .select-desktop_expand {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.rememberland-panel-sidebar-inner .select-desktop_expand::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
  width: 32px;
  height: 32px;
  background: url("../img/icon-chevron-down.svg") no-repeat center center;
  transform-origin: center;
  filter: invert(99%) sepia(7%) saturate(25%) hue-rotate(278deg) brightness(106%) contrast(100%);
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:hover::after {
  opacity: 1;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked::after {
  transform: rotate(180deg);
}
.rememberland-panel-sidebar-inner .select-desktop_expandLabel {
  display: block;
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.rememberland-panel-sidebar-inner .select-desktop_close {
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 0px;
}
.rememberland-panel-sidebar-inner .select-desktop_input {
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  line-height: 64px;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.rememberland-panel-sidebar-inner .select-desktop_label-placeholder {
  height: 64px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  background-color: transparent;
}
.rememberland-panel-sidebar-inner .select-desktop_label img {
  margin-right: 10px;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked + .select-desktop_closeLabel + .select-desktop_options {
  background: #1C1F37;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked + .select-desktop_closeLabel + .select-desktop_options .select-desktop_label {
  height: 64px;
}
.rememberland-panel-sidebar-inner .select-desktop_expand:checked + .select-desktop_closeLabel + .select-desktop_options + .select-desktop_expandLabel {
  display: none;
}
.rememberland-panel-sidebar-inner .select-desktop_input:checked + .select-desktop_label {
  height: 64px;
}
.rememberland-panel-sidebar-inner .rl-create-new-grave {
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 16px;
  padding: 24px;
  font-size: 12px;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  border: 1px solid #EAD87F;
  color: #EAD87F !important;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-inner .rl-create-new-grave img {
  filter: invert(89%) sepia(46%) saturate(476%) hue-rotate(336deg) brightness(94%) contrast(94%);
  margin-right: 10px;
}

.rememberland-panel-sidebar-user {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.rememberland-panel-sidebar-user .rl-user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.29);
  object-fit: contain;
}
.rememberland-panel-sidebar-user .rl-user-settings {
  margin-left: auto;
}
.rememberland-panel-sidebar-user-name {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  margin-left: 8px;
}
.rememberland-panel-sidebar-user-name span {
  font-weight: 400;
}
.rememberland-panel-sidebar-user a {
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar-user a:hover {
  opacity: 0.6;
}

.rememberland-panel-sidebar-mobile {
  background: #1C1F37;
  z-index: 123;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: calc(100vh - (100vh - 100%));
  height: 100svh;
  height: -webkit-fill-available;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}
.rememberland-panel-sidebar-mobile:before {
  content: "";
  position: absolute;
  left: 69.74%;
  right: -113.59%;
  top: -17.75%;
  z-index: -1;
  bottom: 54.72%;
  background: linear-gradient(180deg, #755CDD 0%, #E4559A 100%);
  opacity: 0.45;
  filter: blur(100px);
}
.rememberland-panel-sidebar-mobile:after {
  content: "";
  position: absolute;
  left: -71.54%;
  right: 27.69%;
  top: 99.21%;
  bottom: -62.25%;
  z-index: -1;
  background: linear-gradient(180deg, rgba(255, 228, 157, 0.6) 0%, #E4559A 100%);
  opacity: 0.3;
  filter: blur(100px);
}
.rememberland-panel-sidebar-mobile.show {
  opacity: 1;
  pointer-events: all;
}
.rememberland-panel-sidebar-mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 253, 239, 0.1);
}
.rememberland-panel-sidebar-mobile-header .rememberland-logo-white {
  width: 156px;
  filter: invert(100%) sepia(8%) saturate(33%) hue-rotate(274deg) brightness(108%) contrast(107%);
}
.rememberland-panel-sidebar-mobile-header .close-mobile-hamburger {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(102%) contrast(105%);
}
.rememberland-panel-sidebar-mobile-header .rl-bo-user-mobile-left {
  width: 24px;
  height: 24px;
}
.rememberland-panel-sidebar-mobile-body {
  padding: 20px 24px 90px;
  overflow-y: auto;
  height: calc(100% - 72px);
}
.rememberland-panel-sidebar-mobile-body a {
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 16px;
  padding: 24px;
  font-size: 12px;
  line-height: 14.52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}
.rememberland-panel-sidebar-mobile-body a.read-more, .rememberland-panel-sidebar-mobile-body a.login {
  border: 1px solid #EAD87F;
  color: #EAD87F !important;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-mobile-body a.read-more img, .rememberland-panel-sidebar-mobile-body a.login img {
  filter: invert(89%) sepia(46%) saturate(476%) hue-rotate(336deg) brightness(94%) contrast(94%);
  margin-right: 10px;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu li {
  position: relative;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu li:not(:last-child) {
  margin-bottom: 8px;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu li:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../img/icon-chevron-right.svg") no-repeat center center;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  filter: invert(100%) sepia(0%) saturate(11%) hue-rotate(182deg) brightness(102%) contrast(105%);
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu a {
  font-size: 16px;
  text-transform: none;
  text-align: left;
  letter-spacing: 0;
  color: #757683;
  margin: 0;
  padding: 18px 24px;
  line-height: 24px;
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar-mobile-body .bo-user-menu a:hover, .rememberland-panel-sidebar-mobile-body .bo-user-menu a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}
.rememberland-panel-sidebar-mobile .select {
  display: block;
  width: 100%;
  padding: 20px 24px;
  height: 64px;
  cursor: pointer;
  position: relative;
  margin: 40px 0 20px;
}
.rememberland-panel-sidebar-mobile .select_options {
  padding: 0 24px;
  border: 1px solid rgba(255, 255, 255, 0.47);
  background: #1C1F37;
}
.rememberland-panel-sidebar-mobile .select_expand {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.rememberland-panel-sidebar-mobile .select_expand::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
  width: 32px;
  height: 32px;
  background: url("../img/icon-chevron-down.svg") no-repeat center center;
  transform-origin: center;
  filter: invert(99%) sepia(7%) saturate(25%) hue-rotate(278deg) brightness(106%) contrast(100%);
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rememberland-panel-sidebar-mobile .select_expand:hover::after {
  opacity: 1;
}
.rememberland-panel-sidebar-mobile .select_expand:checked::after {
  transform: rotate(180deg);
}
.rememberland-panel-sidebar-mobile .select_expandLabel {
  display: block;
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}
.rememberland-panel-sidebar-mobile .select_close {
  display: none;
}
.rememberland-panel-sidebar-mobile .select_closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}
.rememberland-panel-sidebar-mobile .select_items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 0px;
}
.rememberland-panel-sidebar-mobile .select_input {
  display: none;
}
.rememberland-panel-sidebar-mobile .select_label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  line-height: 64px;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.rememberland-panel-sidebar-mobile .select_label-placeholder {
  height: 64px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  background-color: transparent;
}
.rememberland-panel-sidebar-mobile .select_label img {
  margin-right: 10px;
}
.rememberland-panel-sidebar-mobile .select_expand:checked + .select_closeLabel + .select_options .select_label {
  height: 64px;
}
.rememberland-panel-sidebar-mobile .select_expand:checked + .select_closeLabel + .select_options + .select_expandLabel {
  display: none;
}
.rememberland-panel-sidebar-mobile .select_input:checked + .select_label {
  height: 64px;
}
.rememberland-panel-sidebar-mobile-footer {
  background: #1C1F37;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 24px;
  border-top: 1px solid rgba(255, 253, 239, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rememberland-panel-sidebar-mobile-footer .rl-lang {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 10px;
}
.rememberland-panel-sidebar-mobile-footer .rl-lang a {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 4px;
  padding: 8px;
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease-out;
}
.rememberland-panel-sidebar-mobile-footer .rl-lang a:hover, .rememberland-panel-sidebar-mobile-footer .rl-lang a.active {
  color: #fff;
}
.rememberland-panel-sidebar-mobile-footer .rl-logout {
  color: #757683;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.rememberland-panel-sidebar-mobile-footer .rl-logout img {
  margin-right: 10px;
  filter: invert(51%) sepia(13%) saturate(295%) hue-rotate(198deg) brightness(88%) contrast(94%);
}

.rl-accordion .inner {
  display: none;
}
.rl-accordion li a {
  display: block;
  padding: 20px 24px;
  position: relative;
  color: #757683;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0;
  text-transform: none;
  transition: all 0.3s ease-out;
}
.rl-accordion li a:hover {
  opacity: 0.6;
}
.rl-accordion li a:after {
  content: "";
  background: url("../img/icon-chevron-down.svg") no-repeat center center;
  transform-origin: center;
  filter: invert(99%) sepia(7%) saturate(25%) hue-rotate(278deg) brightness(106%) contrast(100%);
  position: absolute;
  right: 18px;
  top: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.25;
  transition: all 0.3s ease-out;
}
.rl-accordion li.active a {
  color: #fff;
}
.rl-accordion li.active a:after {
  transform: rotate(180deg);
  transform-origin: center center;
  opacity: 1;
}
.rl-accordion .inner {
  padding-bottom: 1rem;
  text-align: left;
}
.rl-accordion .inner ul {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  list-style-position: inside;
  margin-left: 24px;
  padding-left: 20px;
}
.rl-accordion .inner ul a {
  color: #757683;
  padding: 16px 24px;
  font-size: 12px;
  transition: all 0.3s ease-out;
}
.rl-accordion .inner ul a:hover, .rl-accordion .inner ul a.active {
  background-color: rgba(255, 255, 255, 0.08);
  color: #fff;
}
.rl-accordion .inner ul a::after {
  content: "";
  background: none;
}

.rl-custom-scroll {
  scrollbar-color: #fff #646672;
  scrollbar-width: thin;
  overflow-y: auto;
}

.rl-custom-scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  border-radius: 100px;
}

.rl-custom-scroll::-webkit-scrollbar-track {
  background: rgba(100, 102, 114, 0.1);
  border-radius: 100px;
}

.rl-custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(100, 102, 114, 0.15);
  border-radius: 100px;
}

.rl-custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #757683;
}

.rl-admin-panel-content {
  margin: 116px 40px 0 460px;
  width: calc(100% - 500px);
  position: relative;
  z-index: 99;
}
.rl-admin-panel-content.in-funnel {
  z-index: 120;
}
@media (max-width: 64em) {
  .rl-admin-panel-content {
    margin: 93px 0 0;
    width: 100%;
  }
  .rl-admin-panel-content.rl-bo-user {
    margin-top: 67px;
  }
}

.rl-admin-grave-mobile-link {
  display: none;
}
.rl-admin-grave-mobile-link img {
  filter: invert(98%) sepia(2%) saturate(244%) hue-rotate(187deg) brightness(115%) contrast(100%);
}
@media (max-width: 48em) {
  .rl-admin-grave-mobile-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 12px 16px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
}

.rl-admin-tabs {
  margin: 0 0 24px 0;
  overflow-x: auto;
  padding-bottom: 8px;
}
.rl-admin-tabs ul {
  display: grid;
  grid-template-columns: repeat(7, minmax(140px, 1fr));
  gap: 16px;
}
.rl-admin-tabs ul.col-4 {
  grid-template-columns: repeat(4, minmax(140px, 1fr));
}
.rl-admin-tabs a {
  border: 2px solid rgba(255, 255, 255, 0.47);
  padding: 20px;
  display: block;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  background: transparent;
  position: relative;
  transition: all 0.3s ease-out;
}
.rl-admin-tabs a div {
  display: inline-flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}
.rl-admin-tabs a:hover {
  background: rgba(167, 94, 253, 0.1);
}
.rl-admin-tabs a.active {
  background: #A75EFD;
  border-color: #A75EFD;
}
.rl-admin-tabs a.active figure {
  background-color: #fff;
}
.rl-admin-tabs a.active figure img {
  filter: invert(36%) sepia(31%) saturate(4189%) hue-rotate(239deg) brightness(97%) contrast(87%);
}
.rl-admin-tabs a.deactive {
  pointer-events: none;
  opacity: 0.15;
}
.rl-admin-tabs a.completed {
  border-color: #A75EFD;
}
.rl-admin-tabs a.completed:after {
  content: "";
  position: absolute;
  right: -18px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  width: 16px;
  height: 2px;
  background-color: #A75EFD;
}
.rl-admin-tabs li:last-child a:after {
  content: none;
}
.rl-admin-tabs figure.left {
  border-radius: 8px;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.16);
  margin-bottom: 12px;
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.rl-admin-tabs figure img {
  filter: invert(100%) sepia(6%) saturate(7483%) hue-rotate(276deg) brightness(111%) contrast(101%);
  transition: all 0.3s ease-out;
}
.rl-admin-tabs figure.lock.right {
  border-radius: 8px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  margin-bottom: 0px;
  transition: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rl-admin-tabs figure img.lock {
  filter: invert(100%) sepia(6%) saturate(7483%) hue-rotate(276deg) brightness(111%) contrast(101%);
}
@media (max-width: 64em) {
  .rl-admin-tabs {
    margin: 0 12px 24px;
  }
}
@media (max-width: 48em) {
  .rl-admin-tabs {
    margin: 12px;
  }
  .rl-admin-tabs ul {
    grid-template-columns: repeat(7, minmax(130px, 1fr));
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
  }
  .rl-admin-tabs li {
    scroll-snap-align: start;
  }
  .rl-admin-tabs a {
    padding: 16px;
    font-size: 10px;
  }
}

.rl-admin-content {
  background-color: rgba(255, 255, 255, 0.97);
  padding: 24px;
  font-size: 14px;
}
.rl-admin-content h1,
.rl-admin-content .h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}
@media (max-width: 48em) {
  .rl-admin-content {
    padding: 24px 12px;
    min-height: calc(100vh - 72px);
  }
  .rl-admin-content h1,
  .rl-admin-content .h1 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}

.rl-admin-content-top-nav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 500;
}
.rl-admin-content-top-nav a {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #111;
}

.rl-admin-content-top-text {
  margin-bottom: 24px;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
}
.rl-admin-content-top-text-header {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.rl-admin-content-7-3 {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 16px;
}
@media (max-width: 48em) {
  .rl-admin-content-7-3 {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.rl-admin-content-box {
  background-color: #fff;
  border: 1px solid #E9EBF3;
  padding: 24px;
  position: relative;
}
.rl-admin-content-box.mt-16 {
  margin-top: 16px;
}

.rl-admin-add-user-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  gap: 8px;
}
.rl-admin-add-user-form .rl-form-group {
  margin-bottom: 0;
}
.rl-admin-add-user-form.rl-diary {
  grid-template-columns: auto;
  grid-template-rows: auto;
}
.rl-admin-add-user-form .expand-row {
  grid-column-start: 1;
  grid-column-end: 4;
  height: 180px;
}
.rl-admin-add-user-form .expand-row-submit {
  grid-column-start: 1;
  grid-column-end: 4;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.rl-admin-add-user-form .expand-row-submit .left {
  width: 30%;
  display: flex;
  align-items: center;
}
.rl-admin-add-user-form .expand-row-submit .right {
  width: 70%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
}
.rl-admin-add-user-form .rl-form-button {
  max-width: 220px;
}
.rl-admin-add-user-form .rl-complete-graveyard-button {
  flex: 1;
  height: 56px;
  color: #111;
  border: 1px solid #111;
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 48em) {
  .rl-admin-add-user-form {
    grid-template-columns: 1fr;
  }
  .rl-admin-add-user-form .expand-row,
  .rl-admin-add-user-form .expand-row-submit {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .rl-admin-add-user-form .expand-row-submit {
    flex-flow: column wrap;
  }
  .rl-admin-add-user-form .expand-row-submit .left,
  .rl-admin-add-user-form .expand-row-submit .right {
    width: 100%;
    flex-flow: column wrap;
    align-items: center;
  }
  .rl-admin-add-user-form .expand-row-submit .rl-panel-wizard-btn-skip {
    margin-left: 0;
  }
  .rl-admin-add-user-form .rl-form-button {
    max-width: initial;
  }
}

.rl-doughnut-chart {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Jost", sans-serif;
  font-weight: 500;
}
.rl-doughnut-chart .chart-wrapper {
  position: relative;
}
.rl-doughnut-chart .chart-wrapper canvas {
  max-width: 100%;
}
.rl-doughnut-chart .chart-wrapper #chartdiv {
  width: 100%;
  height: 500px;
}
.rl-doughnut-chart .chart-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 160%;
}
.rl-doughnut-chart .chart-center span {
  font-size: 60%;
  color: #646672;
  letter-spacing: 0.09em;
}
.rl-doughnut-chart .chart-left-count {
  width: 100%;
  display: block;
  margin: 1rem auto;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 4px;
  text-align: center;
}

.rl-bo-users-search-form {
  display: grid;
  grid-template-columns: 4fr 4fr 2fr;
  align-items: center;
  gap: 16px;
}
.rl-bo-users-search-form .column .rl-form-group:last-child {
  margin-bottom: 0;
}
.rl-bo-users-search-form.rl-table-admin-bottom-filters {
  grid-template-columns: 4fr 4fr 2fr;
}
.rl-bo-users-search-form.rl-w-skip-prev {
  grid-template-columns: 1fr 4fr 4fr 2fr 1fr;
}
.rl-bo-users-search-form .column-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
@media (max-width: 48em) {
  .rl-bo-users-search-form .column-2 {
    grid-template-columns: 1fr;
  }
  .rl-bo-users-search-form .column-2 .rl-form-group {
    margin-bottom: 0 !important;
  }
}
.rl-bo-users-search-form .rl-form-button {
  height: 56px;
}
.rl-bo-users-search-form .rl-complete-graveyard-button {
  flex: 1;
  height: 56px;
  color: #111;
  border: 1px solid #111;
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rl-bo-users-search-form.for-kyc {
  display: flex;
  width: 100%;
}
.rl-bo-users-search-form.for-kyc .column:nth-child(2) {
  flex: 1;
}
.rl-bo-users-search-form.for-kyc .column:nth-child(3) {
  width: 180px;
}
.rl-bo-users-search-form.for-kyc .column:nth-child(4) {
  width: 180px;
}
.rl-bo-users-search-form .kyc-text-desc {
  font-size: 12px;
  text-align: right;
}
@media (max-width: 80em) {
  .rl-bo-users-search-form.for-kyc {
    flex-flow: column;
  }
  .rl-bo-users-search-form.for-kyc .column:nth-child(2) {
    width: 100%;
    flex: auto;
  }
  .rl-bo-users-search-form.for-kyc .column:nth-child(3) {
    width: 100%;
  }
  .rl-bo-users-search-form.for-kyc .column:last-child {
    width: 100%;
  }
  .rl-bo-users-search-form .kyc-text-desc {
    text-align: left;
  }
}
@media (max-width: 64em) {
  .rl-bo-users-search-form {
    grid-template-columns: 1fr;
    gap: 8px;
  }
  .rl-bo-users-search-form.rl-w-skip-prev {
    grid-template-columns: 1fr;
  }
  .rl-bo-users-search-form.rl-w-skip-prev .column .rl-form-group:last-child {
    margin-bottom: 0;
  }
  .rl-bo-users-search-form .column {
    text-align: center;
  }
  .rl-bo-users-search-form .column .rl-form-group:last-child {
    margin-bottom: 8px;
  }
}

.rl-bo-user-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rl-bo-user-detail-header-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rl-bo-user-detail-header-left img {
  margin-right: 8px;
}
.rl-bo-user-detail-header-left .username {
  font-size: 14px;
  font-weight: 600;
}
.rl-bo-user-detail-header-left .email {
  font-size: 12px;
}
.rl-bo-user-detail-header-left .username-info.grave {
  display: flex;
  align-items: center;
}
.rl-bo-user-detail-header-left .username-info.grave .email {
  margin: 4px 0 0 4px;
}
@media (max-width: 48em) {
  .rl-bo-user-detail-header-left img {
    width: 48px;
    height: 48px;
  }
}

.rl-user-info-tabs {
  margin: 16px 0 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}
.rl-user-info-tabs.grave {
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 8px;
  padding-bottom: 4px;
}
.rl-user-info-tabs li a {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #EAEBF2;
  border: 1px solid #CECED6;
  color: #646672;
  font-weight: 600;
  word-break: keep-all;
  white-space: nowrap;
  min-width: max-content;
  transition: all 0.3s ease-out;
}
.rl-user-info-tabs li a img {
  filter: invert(42%) sepia(10%) saturate(411%) hue-rotate(194deg) brightness(92%) contrast(91%);
  margin-right: 8px;
}
.rl-user-info-tabs li a.active {
  background-color: #1A1A1A;
  border-color: #1A1A1A;
  color: #fff;
}
.rl-user-info-tabs li a.active img {
  filter: invert(93%) sepia(100%) saturate(27%) hue-rotate(39deg) brightness(106%) contrast(108%);
}
.rl-user-info-tabs li a:hover {
  opacity: 0.6;
}
@media (max-width: 80em) {
  .rl-user-info-tabs.grave {
    grid-template-columns: max-content;
    grid-auto-columns: max-content;
    overflow-x: auto;
  }
}
@media (max-width: 48em) {
  .rl-user-info-tabs {
    grid-template-columns: max-content;
    grid-auto-columns: max-content;
    overflow-x: auto;
  }
}

.rl-content-link {
  display: flex;
  align-items: center;
  color: #1A1A1A;
}

.rl-content-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 16px;
  flex-shrink: 0;
}
.rl-content-icon.video {
  background-color: rgba(58, 149, 255, 0.16);
}
.rl-content-icon.video img {
  width: 20px;
  height: 20px;
  filter: invert(45%) sepia(85%) saturate(1277%) hue-rotate(192deg) brightness(100%) contrast(102%);
}
.rl-content-icon.music {
  background-color: rgba(242, 142, 69, 0.16);
}
.rl-content-icon.music img {
  width: 20px;
  height: 20px;
  filter: invert(62%) sepia(56%) saturate(1396%) hue-rotate(332deg) brightness(106%) contrast(90%);
}
.rl-content-icon.text {
  background-color: rgba(135, 91, 191, 0.16);
}
.rl-content-icon.text img {
  width: 20px;
  height: 20px;
  filter: invert(39%) sepia(25%) saturate(1295%) hue-rotate(224deg) brightness(100%) contrast(88%);
}
.rl-content-icon.image {
  background-color: rgba(220, 78, 86, 0.16);
}
.rl-content-icon.image img {
  width: 20px;
  height: 20px;
  filter: invert(43%) sepia(49%) saturate(1106%) hue-rotate(314deg) brightness(88%) contrast(95%);
}

.rl-progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background-color: #EAEBF2;
  border: 1px solid #E9EBF3;
  position: relative;
}
.rl-progress-bar-fill {
  height: 8px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.rl-progress-bar-fill.pink {
  background-color: #DD89DF;
}
.rl-progress-bar-fill.turquaz {
  background-color: #4EC6CE;
}
.rl-progress-bar-fill.orange {
  background-color: #F88B84;
}

.rl-list-all-verses-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 60px;
  max-height: 300px;
  overflow: auto;
  padding-right: 20px;
}
.rl-list-all-verses-grid .rl-verse-name {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 8px;
}
.rl-list-all-verses-grid .rl-verse-list-item.no-show {
  display: none;
}
.rl-list-all-verses-grid .rl-verse-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  font-weight: 600;
}
.rl-list-all-verses-grid .rl-verse-details-left {
  letter-spacing: 1.5px;
}
.rl-list-all-verses-grid .rl-verse-details-left span {
  color: #999AA7;
}
.rl-list-all-verses-grid .rl-verse-details-right {
  font-size: 14px;
}
.rl-list-all-verses-grid .rl-verse-details-right a {
  display: flex;
}
@media (max-width: 80em) {
  .rl-list-all-verses-grid {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.rl-show-all-verses-btn {
  border: 1px solid #1A1A1A;
  color: #1A1A1A;
  width: 220px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto;
}
@media (max-width: 48em) {
  .rl-show-all-verses-btn {
    margin: 32px auto;
  }
}

.rl-verses-titles {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
}

.rl-add-new-verse-form {
  display: flex;
  align-items: center;
  margin-top: 60px;
  gap: 16px;
}
.rl-add-new-verse-form .rl-form-group {
  margin: 0;
  flex: 1;
}
.rl-add-new-verse-form .rl-form-button {
  width: 220px;
  height: 56px;
}
@media (max-width: 48em) {
  .rl-add-new-verse-form {
    flex-flow: column wrap;
    gap: 8px;
  }
  .rl-add-new-verse-form .rl-form-group {
    width: 100%;
    flex: auto;
  }
  .rl-add-new-verse-form .rl-form-button {
    width: 100%;
  }
}

.rl-content-upload {
  width: 25vw;
  border: 2px dashed #418DFF;
  margin: 0 auto 24px;
  padding: 32px;
  text-align: center;
}
.rl-content-upload img {
  margin: 0 auto;
  filter: invert(42%) sepia(69%) saturate(687%) hue-rotate(182deg) brightness(107%) contrast(101%);
}
.rl-content-upload .upload-text {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 8px;
}
.rl-content-upload.uploaded {
  background-color: #EAF7EE;
  border-color: #56BB67;
}
.rl-content-upload.uploaded .rl-form-or span {
  background-color: #EAF7EE;
}
.rl-content-upload.uploaded img {
  filter: invert(59%) sepia(46%) saturate(500%) hue-rotate(78deg) brightness(99%) contrast(87%);
}
.rl-content-upload.error {
  background-color: #FFF3F3;
  border-color: #B65E49;
}
.rl-content-upload.error .rl-form-or span {
  background-color: #FFF3F3;
}
.rl-content-upload.error img {
  filter: invert(48%) sepia(18%) saturate(1567%) hue-rotate(324deg) brightness(85%) contrast(87%);
}
.rl-content-upload.disabled {
  background-color: #FFFFFF;
  border-color: #EAEBF2;
}
.rl-content-upload.disabled .rl-form-or span {
  background-color: #FFFFFF;
}
.rl-content-upload.disabled img {
  filter: invert(92%) sepia(65%) saturate(2418%) hue-rotate(179deg) brightness(113%) contrast(90%);
}
@media (max-width: 64em) {
  .rl-content-upload {
    width: 100%;
  }
}

.rl-uploaded-files {
  margin: 24px 0;
}
.rl-uploaded-files-header {
  font-weight: 600;
  margin-bottom: 16px;
}
.rl-uploaded-files-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E9EBF3;
}
.rl-uploaded-files .rl-content-link {
  margin-right: 10px;
}
.rl-uploaded-files a {
  flex-shrink: 0;
}

.rl-upload-storage-left {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.rl-upload-storage-left span {
  font-weight: 600;
  margin-left: 4px;
}
@media (max-width: 64em) {
  .rl-upload-storage-left {
    justify-content: flex-start;
    margin-bottom: 16px;
  }
}

.rl-subs-info-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rl-subs-info-top-left {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 3vw;
  width: 40vw;
}
.rl-subs-info-top-left .top {
  text-align: left;
  font-size: 12px;
  color: #757683;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
}
.rl-subs-info-top-left .bottom {
  font-weight: 600;
  font-size: 14px;
}
.rl-subs-info-top-right {
  display: flex;
  align-items: center;
}
@media (max-width: 80em) {
  .rl-subs-info-top-left {
    grid-template-columns: repeat(2, max-content);
    gap: 1vw 3vw;
  }
}
@media (max-width: 48em) {
  .rl-subs-info-top {
    flex-flow: column;
    align-items: flex-start;
    margin-top: 16px;
  }
  .rl-subs-info-top-left {
    grid-template-columns: 1fr;
    gap: 0;
    width: 100%;
  }
  .rl-subs-info-top-left .column {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E9EBF3;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rl-subs-info-top .bottom {
    padding-left: 8px;
  }
}

.rl-account-avatar {
  display: grid;
  grid-template-columns: 100px 36px 36px;
  align-items: flex-start;
  gap: 8px;
}

.rl-gray-square-btn {
  background-color: #EAEBF2;
  padding: 8px;
  display: inline-block;
}
.rl-gray-square-btn img {
  width: 20px;
  height: 20px;
}

.rl-account-details-form {
  margin: 24px 0 40px;
  padding: 0 0 16px;
  border-bottom: 1px solid #E9EBF3;
  display: grid;
  grid-template-columns: repeat(2, 20vw);
  gap: 8px;
}
.rl-account-details-form .rl-account-save-input-btn {
  color: #1A1A1A;
  border: 1px solid;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  padding: 8px 12px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}
.rl-account-details-form .rl-account-change-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 0.3s ease-out;
}
.rl-account-details-form .rl-form-group {
  margin: 0;
}
.rl-account-details-form .rl-form-group input {
  padding-right: 80px;
}
.rl-account-details-form .rl-form-group input:focus {
  border-color: #1A1A1A;
}
.rl-account-details-form .rl-form-group input.rl-pass-w-icon {
  padding-right: 80px;
}
.rl-account-details-form .rl-form-group:focus-within .rl-material-label {
  z-index: 0;
}
.rl-account-details-form .rl-form-group:focus-within .rl-account-save-input-btn {
  opacity: 1;
  pointer-events: all;
  z-index: 10;
}
.rl-account-details-form .rl-form-group:focus-within .rl-account-change-icon {
  opacity: 0;
  z-index: -1;
}
.rl-account-details-form .rl-form-group .rl-icon-link {
  right: 42px;
}
.rl-account-details-form .rl-form-group .rl-account-pass-change {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 0.3s ease-out;
}
@media (max-width: 64em) {
  .rl-account-details-form {
    grid-template-columns: 1fr;
  }
}

.rl-account-cards-grid-item {
  width: 340px;
}
@media (max-width: 48em) {
  .rl-account-cards-grid-item {
    width: 100%;
  }
}

.rl-account-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 340px);
  gap: 40px 8px;
  margin-bottom: 16px;
}
.rl-account-cards-grid .rl-account-card {
  width: 340px;
  padding: 24px 24px 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.rl-account-cards-grid header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rl-account-cards-grid header .card-holder {
  font-size: 14px;
  font-weight: 600;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rl-account-cards-grid header .header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rl-account-cards-grid header a {
  border-radius: 8px;
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.09);
}
.rl-account-cards-grid header .del-btn {
  margin-left: 8px;
}
.rl-account-cards-grid .card-numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin: 32px 0;
}
.rl-account-cards-grid .card-numbers .no {
  font-size: 32px;
}
.rl-account-cards-grid footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}
@media (max-width: 48em) {
  .rl-account-cards-grid {
    grid-template-columns: 1fr;
  }
  .rl-account-cards-grid .rl-account-card {
    width: 100%;
    max-width: 340px;
  }
}

.rl-add-new-card {
  background-color: #EAEBF2;
  border: 2px dashed #757683;
  width: 218px;
  height: 218px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.rl-add-new-card img {
  margin: auto auto 8px;
}
.rl-add-new-card a {
  color: #1A1A1A;
}

.card-related-text {
  font-size: 11px;
  letter-spacing: 2px;
  margin: 16px 0;
}

.grave-name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.rl-change-cc {
  background-color: #EAEBF2;
  padding: 8px;
  color: #1A1A1A;
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 10px;
  font-weight: 500;
  transition: all 0.3s ease-out;
}
.rl-change-cc:hover {
  opacity: 0.6;
}
.rl-change-cc img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.rl-cancel-membership {
  color: #646672;
  font-size: 10px;
}

.rl-welcome-back {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 190px);
}
@media (max-width: 48em) {
  .rl-welcome-back {
    height: calc(100vh - 140px);
  }
}

.hr {
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background-color: #E9EBF3;
}
@media (max-width: 48em) {
  .hr {
    margin: 24px 0;
  }
}

.rl-sign-in-up-slideshow {
  z-index: 0;
  pointer-events: none;
}
@media (max-width: 37.5em) {
  .rl-sign-in-up-slideshow {
    display: none;
  }
}

.rl-payment-slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
  z-index: 10;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  animation-name: imageAnimation;
  animation-duration: 18s;
  animation-iteration-count: infinite;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
  }
  11.11% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  44.44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.rl-payment-slideshow li:nth-child(1) span {
  background-image: url(../img/rl-payment-bg-1.png);
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  animation-delay: 0;
}

.rl-payment-slideshow li:nth-child(2) span {
  background-image: url(../img/rl-payment-bg-2.png);
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  animation-delay: 6s;
}

.rl-payment-slideshow li:nth-child(3) span {
  background-image: url(../img/rl-payment-bg-3.png);
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  animation-delay: 12s;
}

.rl-activate-account {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 10px;
  color: #B65E49;
}
.rl-activate-account img {
  filter: invert(52%) sepia(18%) saturate(5478%) hue-rotate(334deg) brightness(80%) contrast(71%);
  margin-right: 4px;
}

.rl-panel-wizard-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  background-color: rgba(17, 17, 17, 0.6);
}
@media (max-width: 37.5em) {
  .rl-panel-wizard-overlay {
    background-color: #111111;
  }
}

.rl-panel-wizard-home-btn {
  position: absolute;
  right: 42px;
  height: 55px;
  top: 20px;
  z-index: 130;
  padding: 16px 60px;
  background: #A75EFD;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
@media (max-width: 37.5em) {
  .rl-panel-wizard-home-btn {
    right: 24px;
  }
}

.rl-panel-wizard-whatis-btn {
  position: fixed;
  right: 42px;
  height: 55px;
  top: 20px;
  z-index: 130;
  padding: 16px 100px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 64em) {
  .rl-panel-wizard-whatis-btn {
    position: absolute;
  }
}
.rl-panel-wizard-btn-skip {
  font-size: 12px;
  color: #111;
}
.rl-panel-wizard-btn-skip.w-icon {
  display: flex;
  align-items: center;
  gap: 2px;
}
.rl-panel-wizard-btn-skip.w-icon img {
  width: 16px;
  height: 16px;
}
@media (max-width: 64em) {
  .rl-panel-wizard-btn-skip.w-icon {
    justify-content: center;
  }
}

.rl-panel-wizard-btn-skip.w-icon.left img {
  transform: rotate(-180deg);
  width: 16px;
  height: 16px;
}

.rl-new-testament-form {
  display: flex;
  align-items: center;
  gap: 16px;
}
.rl-new-testament-form .rl-form-button {
  width: 220px;
  height: 56px;
}
.rl-new-testament-form .rl-form-group {
  margin-bottom: 0;
}
@media (max-width: 48em) {
  .rl-new-testament-form {
    flex-flow: column wrap;
  }
  .rl-new-testament-form .column, .rl-new-testament-form .rl-form-button {
    width: 100%;
  }
}

.rl-create-new-testament-steps {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 16px;
  justify-content: center;
  gap: 24px;
  text-align: center;
}
.rl-create-new-testament-steps .step-no {
  height: 24px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rl-create-new-testament-steps .text {
  color: rgba(153, 154, 167, 0.4);
}
.rl-create-new-testament-steps a {
  color: rgba(153, 154, 167, 0.4);
}
.rl-create-new-testament-steps a.active {
  color: #A75EFD;
}
.rl-create-new-testament-steps a.active .step-no {
  color: #A75EFD;
}
.rl-create-new-testament-steps a.active .text {
  color: #A75EFD;
}
.rl-create-new-testament-steps a.completed .step-no {
  border-color: #56BB67;
}
.rl-create-new-testament-steps a.completed .text {
  color: #56BB67;
}
.rl-create-new-testament-steps a.completed img {
  filter: invert(62%) sepia(22%) saturate(1021%) hue-rotate(78deg) brightness(99%) contrast(86%);
}

.rl-add-new-test-btn {
  display: inline-block !important;
  width: auto !important;
  margin: 24px auto;
  background-color: transparent;
  border: 1px solid #111;
  color: #111;
  padding: 17px;
  font-weight: 600;
  font-size: 14px;
}

.bg-white {
  background-color: #fff !important;
}

.page-404 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #231B39 url("../img/landing-bg-desktop-min.jpg") no-repeat center 16%;
  background-size: cover;
}
.page-404 p {
  font-family: "Jost", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 32px;
  margin: 3vh auto 8vh;
  letter-spacing: 6px;
}
.page-404 a {
  display: inline-block;
  padding: 18px 80px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  color: #1A1A1A;
}
@media (max-width: 48em) {
  .page-404 p {
    font-size: 20px;
  }
  .page-404 a {
    padding: 16px 40px;
  }
}

.rl-welcome {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #231B39 url("../img/landing-bg-desktop-min.jpg") no-repeat center 16%;
  background-size: cover;
  color: #fff;
  text-align: center;
}
.rl-welcome h2 {
  font-family: "Jost", sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 4px;
  margin: 60px auto 16px;
}
.rl-welcome p {
  font-size: 20px;
  max-width: 900px;
  margin: 0 auto 120px;
}
.rl-welcome a {
  display: inline-block;
  padding: 18px 80px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  color: #1A1A1A;
}
.rl-welcome.admin p {
  font-size: 16px;
  margin-bottom: 80px;
}
.rl-welcome .rl-special-offer {
  position: relative;
  padding: 64px 120px;
  text-align: center;
  border: 1px solid #FFE878;
}
.rl-welcome .rl-special-offer:after {
  content: "";
  position: absolute;
  top: 10px;
  left: -16px;
  width: 103%;
  height: 95%;
  z-index: -1;
  border: 1px solid #FFE878;
}
.rl-welcome .rl-special-offer h3 {
  font-size: 20px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 6px;
}
.rl-welcome .rl-special-offer p {
  font-size: 14px;
  margin: 16px auto 48px;
  width: 80%;
}
.rl-welcome .rl-special-offer .rl-special-offer-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
}
.rl-welcome .rl-special-offer .rl-special-offer-buttons a {
  color: #fff;
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 6px;
  padding: 24px 80px;
  border: 1px solid #FFE878;
  background-color: rgba(19, 19, 19, 0.12);
}
.rl-welcome .rl-special-offer .rl-go-to-home {
  color: #fff;
  background-color: transparent;
  text-transform: uppercase;
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 6px;
  text-decoration: underline;
  padding: 0;
}
@media (max-width: 48em) {
  .rl-welcome {
    position: relative;
    min-height: 100vh;
    padding-bottom: 40px;
    background-size: 200%;
    background-position-y: 0;
  }
  .rl-welcome .img-heart {
    width: 120px;
    height: 120px;
  }
  .rl-welcome h2 {
    font-size: 24px;
  }
  .rl-welcome p {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .rl-welcome a {
    padding: 16px 40px;
    width: 100%;
  }
  .rl-welcome .rl-special-offer {
    padding: 48px 32px;
  }
  .rl-welcome .rl-special-offer:after {
    top: -15px;
    left: 10px;
    width: 94%;
    height: 106%;
    border: 1px solid #FFE878;
  }
  .rl-welcome .rl-special-offer h3 {
    font-size: 16px;
    letter-spacing: 4px;
  }
  .rl-welcome .rl-special-offer p {
    font-size: 14px;
    margin: 16px auto 32px;
    width: 90%;
  }
  .rl-welcome .rl-special-offer .rl-special-offer-buttons {
    grid-template-columns: 1fr;
    margin-bottom: 32px;
  }
  .rl-welcome .rl-special-offer .rl-special-offer-buttons a {
    font-size: 14px;
    padding: 16px;
  }
  .rl-welcome .rl-special-offer .rl-go-to-home {
    font-size: 14px;
  }
}

.rl-select-grave-type {
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #231B39 url("../img/select-grave-bg.jpg") no-repeat center 16%;
  background-size: cover;
  color: #fff;
  text-align: center;
}
@media (max-width: 48em) {
  .rl-select-grave-type {
    background-image: url("../img/select-grave-bg-mobile.jpg");
    background-position-y: 0;
    background-attachment: fixed;
  }
}

.rememberland-landing-for-whom {
  padding: 120px 40px 0;
  text-align: center;
}
.rememberland-landing-for-whom-wrapper {
  max-width: 62vw;
  width: 100%;
  margin: 0 auto;
}
.rememberland-landing-for-whom-top {
  color: #fff;
  text-transform: uppercase;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 4px;
  margin-bottom: 8vh;
}
.rememberland-landing-for-whom-top .line-1 {
  margin-bottom: 60px;
}
.rememberland-landing-for-whom-bottom {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.rememberland-landing-for-whom-bottom img {
  margin: 0 auto;
}
.rememberland-landing-for-whom-bottom-w-link {
  text-align: center;
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 48%;
}
.rememberland-landing-for-whom-bottom .title {
  margin: 30px auto 32px;
  max-width: 280px;
}
.rememberland-landing-for-whom-bottom a {
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  padding: 28px 48px;
  color: #fff;
  border: 1px solid #FFE878;
  background-color: rgba(17, 17, 17, 0.12);
  transition: all 0.3s ease-out;
}
.rememberland-landing-for-whom-bottom a:hover {
  opacity: 0.6;
}
@media (max-width: 48em) {
  .rememberland-landing-for-whom {
    padding: 90px 0 20px;
  }
  .rememberland-landing-for-whom-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .rememberland-landing-for-whom-top {
    margin-bottom: 50px;
  }
  .rememberland-landing-for-whom-top .line-1 {
    margin-bottom: 30px;
  }
  .rememberland-landing-for-whom-top .line-2 {
    font-size: 14px;
  }
  .rememberland-landing-for-whom-bottom .middle {
    display: none;
  }
  .rememberland-landing-for-whom-bottom img {
    width: 120px;
    height: 120px;
  }
  .rememberland-landing-for-whom-bottom div {
    width: 100%;
    margin-bottom: 72px;
  }
  .rememberland-landing-for-whom-bottom .title {
    margin: 16px auto;
    max-width: 100%;
  }
  .rememberland-landing-for-whom-bottom-w-link {
    font-size: 14px;
    line-height: 32px;
  }
  .rememberland-landing-for-whom-bottom a {
    font-size: 14px;
    padding: 20px 48px;
  }
}

.rl-payment-guardian {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: calc(100vh - 93px);
  margin-top: 93px;
}
.rl-payment-guardian-left {
  position: relative;
}
.rl-payment-guardian-left img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rl-payment-guardian-right {
  padding: 24px 8vw;
  text-align: center;
}
.rl-payment-guardian.payment-verses .rl-payment-guardian-select-price {
  grid-auto-columns: max-content;
  max-width: 260px;
}
.rl-payment-guardian.payment-verses .rl-payment-guardian-select-price a {
  width: 260px;
}
.rl-payment-guardian.payment-verses .rl-payment-guardian-method {
  margin-top: 40px;
}
.rl-payment-guardian .rl-verse-list-item {
  margin: 48px auto;
  max-width: 380px;
}
@media (max-width: 48em) {
  .rl-payment-guardian .rl-verse-list-item {
    margin: 24px auto 32px;
  }
}
.rl-payment-guardian .rl-verse-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: 600;
}
.rl-payment-guardian .rl-verse-details-left {
  letter-spacing: 2px;
}
.rl-payment-guardian .rl-verse-details-left span {
  color: #757683;
}
.rl-payment-guardian h2 {
  font-weight: 600;
  font-size: 24px;
}
.rl-payment-guardian .discount-text {
  margin: 24px auto;
  background: linear-gradient(90deg, #7628D9 0%, #7B3FFC 100%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  color: #FFE878;
  border-radius: 8px;
  white-space: nowrap;
}
.rl-payment-guardian .discount-text img {
  margin-right: 8px;
  filter: invert(87%) sepia(25%) saturate(845%) hue-rotate(347deg) brightness(105%) contrast(104%);
}
.rl-payment-guardian .rl-payment-guardian-select-price {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 16px;
  max-width: 540px;
  margin: auto;
}
.rl-payment-guardian .rl-payment-guardian-select-price a {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #CECED6;
  padding: 32px 32px 16px;
  font-size: 12px;
  color: #1A1A1A;
  transition: all 0.3s ease-out;
}
.rl-payment-guardian .rl-payment-guardian-select-price a.active {
  background: linear-gradient(72.05deg, #A75EFD 0.15%, #755BDF 100.15%);
  color: #fff;
}
.rl-payment-guardian .rl-payment-guardian-select-price a.active img.passive {
  display: none;
}
.rl-payment-guardian .rl-payment-guardian-select-price a.active img.active {
  display: block;
}
.rl-payment-guardian .rl-payment-guardian-select-price a img.passive {
  display: block;
}
.rl-payment-guardian .rl-payment-guardian-select-price a img.active {
  display: none;
}
.rl-payment-guardian .rl-payment-guardian-select-price .price {
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
}
.rl-payment-guardian .rl-payment-guardian-select-price .time-frame {
  margin-bottom: 20px;
}
.rl-payment-guardian .rl-payment-guardian-select-price img {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
}
.rl-payment-guardian .rl-payment-guardian-select-price img.passive {
  filter: invert(41%) sepia(12%) saturate(375%) hue-rotate(194deg) brightness(94%) contrast(89%);
}
.rl-payment-guardian .rl-payment-guardian-select-price.discounted a {
  padding: 16px 32px 16px;
}
.rl-payment-guardian .rl-payment-guardian-select-price.discounted a .previous-price {
  position: relative;
  font-weight: 600;
  font-size: 20px;
}
.rl-payment-guardian .rl-payment-guardian-select-price.discounted a .previous-price:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: #111;
  transform: rotate(-4.67deg);
}
.rl-payment-guardian .rl-payment-guardian-select-price.discounted a.active .previous-price:after {
  background-color: #fff;
}
.rl-payment-guardian .rl-payment-guardian-select-price.discounted .time-frame {
  margin-bottom: 6px;
}
.rl-payment-guardian .rl-payment-guardian-method {
  margin: 40px auto 0;
  max-width: 460px;
}
.rl-payment-guardian .rl-payment-guardian-method h2 {
  margin-bottom: 24px;
}
.rl-payment-guardian .rl-payment-guardian-method-options {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}
.rl-payment-guardian .rl-payment-guardian-method-options a {
  border: 1px solid #1A1A1A;
  color: #1A1A1A;
  font-size: 14px;
  padding: 16px;
  transition: all 0.3s ease-out;
}
.rl-payment-guardian .rl-payment-guardian-method-options a.active {
  background-color: #1A1A1A;
  color: #fff;
  border: 0;
}
.rl-payment-guardian .rl-payment-guardian-method-options a.disabled {
  border: 1px solid #CECED6;
  color: #CECED6;
  font-size: 14px;
  padding: 16px;
  transition: all 0.3s ease-out;
}
.rl-payment-guardian .rl-payment-guardian-method-options-content {
  margin: 40px 0 0;
  text-align: left;
}
.rl-payment-guardian .rl-payment-guardian-method-options-content .form-label span {
  margin: 0 6px;
}
.rl-payment-guardian .rl-payment-guardian-method-options-content .rl-form-button {
  display: inline-block;
  margin: 32px auto 0;
  width: 220px;
}
.rl-payment-guardian h4 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}
.rl-payment-guardian .rl-save-card-info {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #646672;
}
.rl-payment-guardian .form-checkbox-label {
  font-size: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 64em) {
  .rl-payment-guardian {
    grid-template-columns: 1fr;
    min-height: calc(100vh - 73px);
    margin-top: 73px;
  }
  .rl-payment-guardian-left {
    display: none;
  }
  .rl-payment-guardian-right {
    padding: 40px 16px;
  }
  .rl-payment-guardian-right .discount-text {
    margin-top: 16px;
    padding: 10px 16px;
    font-size: 13px;
    width: 100%;
  }
  .rl-payment-guardian-right .rl-payment-guardian-select-price {
    max-width: 100%;
  }
  .rl-payment-guardian-right .rl-payment-guardian-select-price img {
    top: 8px;
    right: 8px;
  }
  .rl-payment-guardian .rl-payment-guardian-method {
    margin-top: 40px;
  }
  .rl-payment-guardian .rl-payment-guardian-method h2 {
    margin-bottom: 24px;
  }
  .rl-payment-guardian .rl-payment-guardian-method-options-content {
    margin-top: 24px;
  }
  .rl-payment-guardian .rl-payment-guardian-method-options-content .rl-form-button {
    width: 100%;
  }
}

.rl-card-expire-info {
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
}

.rl-form-group {
  margin-bottom: 8px;
  position: relative;
}
.rl-form-group-material {
  position: relative;
}
.rl-form-group-material .rl-material-input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  padding: 18px 16px 8px 16px;
  height: 56px;
  border: 1px solid #CECED6;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
}
.rl-form-group-material .rl-material-input.rl-pass-w-icon {
  padding-right: 46px;
}
.rl-form-group-material.error .rl-material-input {
  border-color: #B65E49;
  background-color: #FFF3F3;
}
.rl-form-group-material.error .rl-material-label {
  z-index: 1;
}
.rl-form-group-material .rl-material-label {
  position: absolute;
  display: block;
  bottom: 20px;
  left: 16px;
  transition: all 200ms;
  font-size: 14px;
  color: #999AA7;
  z-index: 1;
  line-height: 1;
}
.rl-form-group-material .rl-icon-link {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.rl-form-group-material .rl-material-input:focus ~ .rl-material-label,
.rl-form-group-material .rl-material-input.focused ~ .rl-material-label {
  bottom: 32px;
  font-size: 12px;
  color: #646672;
}
.rl-form-group-material .rl-material-input:active,
.rl-form-group-material .rl-material-input:focus {
  cursor: text;
}
.rl-form-group .error-line {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-top: 4px;
  color: #B65E49;
}
.rl-form-group .error-line img {
  filter: invert(39%) sepia(83%) saturate(361%) hue-rotate(324deg) brightness(95%) contrast(93%);
  margin-right: 4px;
}

.rl-form-remember-forgot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.rl-forgot-pass {
  color: #1A1A1A;
  text-decoration: underline;
  font-size: 12px;
  line-height: 1;
}

.rl-form-button {
  text-align: center;
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 17px;
  font-weight: 600;
  font-size: 14px;
  background-color: #1A1A1A;
  color: #fff;
  transition: all 0.3s ease-out;
}
.rl-form-button.reset-pass-btn {
  margin-top: 32px;
}
.rl-form-button:hover {
  opacity: 0.6;
}

.rl-form-or {
  position: relative;
  margin: 32px 0;
  min-height: 1px;
  font-size: 10px;
}
.rl-form-or:before {
  content: "";
  height: 0.5px;
  width: 70%;
  background-color: #CECED6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.rl-form-or span {
  background-color: #fff;
  padding: 0 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: #646672;
}

.rl-form-social-btns {
  /* display: grid; */
  grid-template-columns: repeat(2, 11fr);
  gap: 8px;
  margin-bottom: 32px;
}
.rl-form-social-btns a {
  font-size: 14px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #fff;
  transition: all 0.3s ease-out;
}
.rl-form-social-btns a img {
  margin-right: 10px;
}
.rl-form-social-btns a:hover {
  opacity: 0.8;
}
.rl-form-social-btns-google {
  background-color: #4E95EC;
}
.rl-form-social-btns-facebook {
  background-color: #425893;
}

.rl-sign-up-or-in {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  text-align: center;
  color: #646672;
}
.rl-sign-up-or-in a {
  color: #1A1A1A;
  font-weight: 600;
}

.rl-modal-img-top {
  margin: 0 auto 32px;
}

@media (max-width: 48em) {
  .rl-form-remember-forgot {
    margin-bottom: 24px;
  }
  .rl-form-or {
    margin: 24px 0;
  }
  .rl-form-social-btns a {
    font-size: 10px;
    padding: 13px 7px;
  }
  .rl-form-social-btns {
    margin-bottom: 24px;
  }
  .rl-form-button.reset-pass-btn {
    margin-top: 24px;
  }
}
.rl-sign-in-white-outer {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: 100px 16px;
}
.rl-sign-in-white-outer .rl-logo {
  margin: 0 auto 80px;
}
@media (max-width: 48em) {
  .rl-sign-in-white-outer {
    padding: 80px 16px;
  }
  .rl-sign-in-white-outer .rl-logo {
    margin: 0 auto 70px;
  }
}

.rl-sign-in-white-box {
  border: 1px solid #CECED6;
  padding: 56px 96px;
  max-width: 700px;
  margin: auto;
  text-align: center;
}
.rl-sign-in-white-box .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.rl-sign-in-white-box .desc {
  font-size: 14px;
  margin-bottom: 40px;
}
@media (max-width: 48em) {
  .rl-sign-in-white-box {
    padding: 56px 16px 40px;
  }
  .rl-sign-in-white-box .title {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .rl-sign-in-white-box .desc {
    font-size: 12px;
    margin-bottom: 24px;
  }
}